import { persistence } from "@/store/persistence";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import createMutationsSharer from "vuex-shared-mutations";
import Cookies from "js-cookie";
import user from "./user";
import message from "./message";
import status from "./status";
import dumbapp from "./dumbapp";
import help from "./help";
import drafts from "./drafts";
import locks from "./locks";
import tutorial from "./tutorial";
import autocrypt from "./autocrypt";
import rolodex from "./rolodex";
import whitelabel from "./whitelabel";
import palette from "./palette";

Vue.use(Vuex);

let cookieDomain = undefined;

if (
    window.location.hostname === process.env.VUE_APP_COOKIE_DOMAIN ||
    window.location.hostname.endsWith("." + process.env.VUE_APP_COOKIE_DOMAIN)
) {
    cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
}

const vuexCookie = new VuexPersistence({
    restoreState: (key, storage) => Cookies.getJSON(key),
    saveState: (key, state, storage) => {
        Cookies.set(key, state, {
            expires: 1,
            domain: cookieDomain,
        });
    },
    modules: ["user"],
    filter(mutation) {
        return mutation.type.startsWith("user/");
    },
});

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {
        async updateStatus({ dispatch, getters }) {
            dispatch("user/checkAuthExpiration");
            let loggedIn = getters["user/loggedIn"];

            if (loggedIn) {
                try {
                    await dispatch("message/update");
                } catch (err) {
                    console.error("Error retrieving messages and status", err);
                    await dispatch("status/update");
                }
            } else {
                await dispatch("status/update");
            }
        },
    },
    modules: {
        user,
        message,
        status,
        dumbapp,
        help,
        drafts,
        locks,
        tutorial,
        autocrypt,
        rolodex,
        whitelabel,
        palette,
    },
    plugins: [
        createMutationsSharer({
            predicate: (mutation, state) => {
                if (
                    mutation.type.startsWith("user/") ||
                    mutation.type.startsWith("dumbapp/") ||
                    mutation.type.startsWith("help/")
                ) {
                    return true;
                }
                if (
                    mutation.type.startsWith("rolodex/") &&
                    !mutation.type.startsWith("rolodex/debug")
                ) {
                    return true;
                }

                return false;
            },
        }),
        vuexCookie.plugin,
        persistence.plugin,
    ],
});
