import Api from "@/lib/api/WalletApi";
import ChronicaApi from "@/lib/api/ChronicaApi.ts";
import {NftLoader, MetadataLoader } from "@blockwell/nft";
import { BookHelper } from "@blockwell/book";
import { ApiMinerBookClient } from "@blockwell/book/lib/ApiMinerBookClient";
import { LocalForageCache } from "@blockwell/cache/lib/LocalForageCache";
import { Chain, DeferredBatcherController } from "@blockwell/chain-client";
import { debounce } from "@blockwell/util";
import axios from "axios";
import * as Cookies from "js-cookie";

export default {
    namespaced: true,
    state: {
        auth: null,
        email: null,
        account: null,
        expiration: null,
        redirect: null
    },
    getters: {
        api(state) {
            let api = new Api(state.auth);
            Object.freeze(api.contracts);
            return Object.freeze(api);
        },
        loggedIn(state) {
            return state.expiration > Date.now();
        },
        chronica(state) {
            return new ChronicaApi(state.auth);
        },
        metadataLoader(state) {
            return new MetadataLoader(axios.create(), new LocalForageCache("nft-metadata", 180 * 1000));
        },
        nftLoader() {
            return new NftLoader(new LocalForageCache("nft-tokens", 5 * 1000), axios.create());
        },
        bookHelper(state, getters) {
            return new BookHelper(new ApiMinerBookClient(getters.api.apiminer, getters.api.contracts), new LocalForageCache("book", 360 * 1000));
        },
        /**
         * @return {ExecutorParameters}
         */
        executorParameters(state, getters) {
            return {
                walletApi: getters.api,
                contracts: getters.api.contracts
            }
        },
        batches() {
            let batches = new DeferredBatcherController(Chain,
                debounce(
                    () => {
                        batches.execute();
                    },
                    50,
                    { maxWait: 100 }
                ));

            return batches;
        }
    },
    mutations: {
        save_auth(state, {auth, email}) {
            state.auth = auth;
            state.email = email;
            state.expiration = Date.now() + 60 * 60 * 24 * 1000;
        },
        save_account(state, account) {
            state.account = account;
        },
        save_redirect(state, redirect) {
            state.redirect = redirect;
        },
        clear(state) {
            state.auth = null;
            state.email = null;
            state.account = null;
            state.expiration = null;
            state.redirect = null;
        }
    },
    actions: {
        async login({commit, getters}, {email, password}) {
            /**
             * @type {Api}
             */
            const api = getters.api;
            let res = await api.login(email, password);
            if (res.data.token) {
                if (res.data.redirect) {
                    commit('save_redirect', res.data.redirect);
                }

                commit('save_auth', {
                    auth: res.data.token,
                    email: email
                });
                commit('save_account', res.data.account);
            } else {
                throw new Error("Server response is invalid, cannot log in.");
            }
        },
        async register({commit, getters}, {email, password}) {
            /**
             * @type {Api}
             */
            const api = getters.api;
            let res = await api.register(email, password, Cookies.get('bw_referrer'));
            if (res.data.token) {
                commit('save_auth', {
                    auth: res.data.token,
                    email: email
                });
                commit('save_account', res.data.account);
            } else {
                throw new Error("Server response is invalid, cannot log in.");
            }
        },
        logout({commit}) {
            commit('clear');
        },
        checkAuthExpiration({commit, state}) {
            if (state.expiration && state.expiration <= Date.now()) {
                commit('clear');
                return true;
            }
            return false;
        }
    }
};
