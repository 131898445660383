import { walletProvider } from "@/components/dumbapp/init";
import { Dumbapp, DumbappSubmission, DumbappSubmissionData, update } from "@blockwell/dumbapp";
import moment from "moment";
import * as R from "rambdax";
import Vue from "vue";

function defaultState() {
    return {
        submissionData: {},
        dumbapps: {},
        submissionDataTimestamps: {},
        dumbappsTimestamps: {},
        recent: [],
        newErrors: false,
        walletType: null,
        paymentCurrencyCode: null,
        trackingData: {},
    };
}

export default {
    namespaced: true,
    // Can't use defaultState() because IDEs don't understand it
    state: {
        submissionData: {},
        dumbapps: {},
        submissionDataTimestamps: {},
        dumbappsTimestamps: {},
        recent: [],
        newErrors: false,
        walletType: null,
        paymentCurrencyCode: null,
        trackingData: {},
    },
    mutations: {
        add_submission(state, submission) {
            let data = submission.data;
            let dumbapp = submission.dumbapp;
            if (!(dumbapp instanceof Dumbapp)) {
                dumbapp = new Dumbapp(dumbapp);
            }
            if (!(data instanceof DumbappSubmissionData)) {
                data = new DumbappSubmissionData(data);
            }
            Vue.set(state.submissionData, data.id, data);
            Vue.set(state.submissionDataTimestamps, data.id, Date.now());
            Vue.set(state.dumbapps, dumbapp.shortcode, dumbapp);
            Vue.set(state.dumbappsTimestamps, dumbapp.shortcode, Date.now());
        },
        add_recent(state, submission) {
            console.log("adding recent", submission);
            state.recent.unshift(submission.data.id);

            if (state.recent.length > 5) {
                state.recent.splice(5);
            }
        },
        /**
         * @param state
         * @param {DumbappSubmission[]} recent
         */
        replace_recent(state, recent) {
            console.log(
                "replacing recent",
                recent.map((it) => it.data.id),
                recent
            );
            state.recent = recent.map((it) => it.data.id);
        },
        update_submission(state, submissionData) {
            let data = submissionData;
            if (!(data instanceof DumbappSubmissionData)) {
                data = new DumbappSubmissionData(data);
            }
            Vue.set(state.submissionData, data.id, data);
        },
        clear(state) {
            Object.assign(state, defaultState());
        },
        set_new_errors(state) {
            state.newErrors = true;
        },
        clear_new_errors(state) {
            state.newErrors = false;
        },
        set_wallet_type(state, type) {
            state.walletType = type;
        },
        set_payment_currency(state, code) {
            state.paymentCurrencyCode = code;
        },
        add_tracking(state, { key, data }) {
            if (!state.trackingData[key]) {
                Vue.set(state.trackingData, key, []);
            }
            state.trackingData[key].push(data);
        },
        remove_tracking(state, { key, id }) {
            if (state.trackingData[key]) {
                let index = state.trackingData[key].findIndex((it) => it.id === id);
                console.log("remove_tracking", id, index);
                state.trackingData[key].splice(index, 1);
            }
        },
    },
    getters: {
        recentSubmissions(state) {
            let dumbapps = state.dumbapps;
            let datas = state.submissionData;
            let submissions = [];

            for (let recent of state.recent) {
                let data = datas[recent];
                let dumbapp = dumbapps[data.shortcode];

                submissions.push(new DumbappSubmission(dumbapp, data));
            }
            return submissions;
        },
        updateNeeded(state) {
            for (let data of Object.values(state.submissionData)) {
                if (data.pending()) {
                    return true;
                }
            }
            return false;
        },
        submissions(state) {
            let submissions = {};
            for (let data of Object.values(state.submissionData)) {
                submissions[data.id] = new DumbappSubmission(state.dumbapps[data.shortcode], data);
            }
            return submissions;
        },
        /**
         *
         * @param state
         * @returns {Record<string, TrackingData[]>}
         */
        tracking(state) {
            let tracking = {};

            for (let [key, val] of Object.entries(state.trackingData)) {
                let tracks = [];
                for (let track of val) {
                    /**
                     * @type {TrackingData}
                     */
                    let data = R.clone(track);
                    let sub = new DumbappSubmissionData(state.submissionData[track.id]);
                    if (sub.pending()) {
                        data.status = "pending";
                    } else if (sub.error()) {
                        data.status = "error";
                    } else {
                        data.status = "completed";
                    }

                    data.submission = sub;

                    tracks.push(data);
                }

                tracking[key] = tracks;
            }
            return tracking;
        },
    },
    actions: {
        async loadRecent({ rootGetters, commit, state, getters }) {
            /**
             * @type {DumbappSubmission[]}
             */
            let submissions = [].concat(getters["recentSubmissions"]);
            console.log("loadRecent subs", submissions);

            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];

            /**
             * @type {DumbappSubmission[]}
             */
            let recent = await api.getRecentSubmissions();

            if (recent && recent.length > 0) {
                let added = false;
                for (let sub of recent) {
                    if (!submissions.find((it) => it.id === sub.id)) {
                        added = true;
                        commit("add_submission", sub);
                        submissions.push(sub);
                    }
                }
                if (added) {
                    submissions.sort((a, b) => {
                        return moment(b.data.created).diff(a.data.created);
                    });

                    if (submissions.length > 5) {
                        submissions = submissions.slice(0, 5);
                    }

                    commit("replace_recent", submissions);
                }
            }
        },
        /**
         *
         * @param commit
         * @param resultState {ExecutionResultState}
         * @returns {Promise<void>}
         */
        dumbappSubmitted({ commit }, resultState) {
            commit("add_submission", resultState.submission);
            commit("add_recent", resultState.submission);
        },
        async updateStatuses({ rootGetters, commit, getters, state }, { component }) {
            if (!getters.updateNeeded) {
                return;
            }
            console.log("updateStatuses dispatch: running updates");

            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];
            const parameters = rootGetters["user/executorParameters"];

            for (let data of Object.values(state.submissionData)) {
                let dumbapp = state.dumbapps[data.shortcode];
                let updated = await update(walletProvider, new DumbappSubmission(dumbapp, data), parameters);
                if (updated) {
                    if (updated.success === true) {
                        commit("update_submission", updated.data);

                        if (rootGetters["user/loggedIn"]) {
                            api.updateDumbappSubmission(updated.data).catch(console.error);
                        }

                        if (component) {
                            let submission = new DumbappSubmission(dumbapp, updated.data);
                            if (updated.completed) {
                                component.$emit("completed", submission);
                            } else
                            if (updated.failed) {
                                component.$emit("error", submission);
                                commit("set_new_errors");
                            }

                            component.$emit("updated", submission);
                        }
                    } else {
                        console.error("Failed to update Submission", updated);
                    }
                }
            }
        },
        async updateSubmission({ rootGetters, commit, state }, { id, updates }) {
            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];

            let data = state.submissionData[id];
            if (!data) {
                let submission = await api.getDumbappSubmission(id);
                commit("add_submission", submission);
            }

            data = Object.assign({}, data, updates);

            commit("update_submission", data);
            if (rootGetters["user/loggedIn"]) {
                api.updateDumbappSubmission(data).catch(console.error);
            }
        },
        async loadSubmission({ rootGetters, state, commit }, id) {
            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];
            let data = state.submissionData[id];
            if (!data) {
                let submission = await api.getDumbappSubmission(id);
                commit("add_submission", submission);
            }
        },
        trackSubmission({ state, commit }, { key, data }) {
            console.log("trackSubmission", key, data);
            /**
             * @type {DumbappSubmissionData}
             */
            let sub = state.submissionData[data.id];
            if (!sub) {
                throw new Error("Submission must exist to be tracked");
            }

            commit("add_tracking", { key, data });
        },
        removeTracking({ state, commit }, { key, id }) {
            commit("remove_tracking", { key, id });
        },
    },
};
