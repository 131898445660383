import { getNetwork } from "@/assets/lib/networks";
import * as util from "@/assets/lib/util";
import Clipboard from "@/components/Clipboard.vue";
import { chainEqual } from "@blockwell/chains";
import ProgressCircle from "@/components/ProgressCircle.vue";
import { Happ } from "@/views/happs/Happ";
import { mapGetters } from "vuex";
export default {
    name: "ContractLoader",
    components: { Clipboard, ProgressCircle },
    props: {
        name: {
            type: String,
        },
        path: {
            type: String,
        },
        label: String,
        deployer: [String, Array],
        common: Array,
        loadClick: Function,
        loadContract: Function,
        hideRecents: Boolean,
        hideDeployed: Boolean,
        noLabel: Boolean,
        features: [String, Array],
        network: [String, Number],
    },
    data() {
        return {
            loading: false,
            networkChoices: null,
            errorMessage: null,
            recents: {
                user: [],
                deployer: [],
            },
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
        recentsDependencies() {
            return {
                hide: this.hideRecents,
                path: this.path,
                deployer: this.deployer,
            };
        },
    },
    watch: {
        recentsDependencies: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!val.hide) {
                    Happ.loadRecents(val.path, val.deployer).then((it) => {
                        this.recents = {
                            deployer: it.deployer,
                            user: it.user.filter((it) => !this.network || chainEqual(it.network, this.network)),
                        };
                    });
                }
            },
        },
    },
    methods: {
        search(query) {
            let trimmed = query.trim();
            if (!trimmed) {
                return [];
            }
            if (util.addressRegex.test(trimmed) || /^https?:\/\//i.test(trimmed)) {
                return [];
            }
            else {
                return this.api.contractSearch(trimmed, this.features, this.network);
            }
        },
        autocompleteUpdate(event) {
            let target = event.target;
            let input = target.value;
            let trimmed = input.trim();
            if (trimmed) {
                if (util.addressRegex.test(trimmed)) {
                    this.addressFound({ address: trimmed });
                    return;
                }
                if (/^https?:\/\//i.test(trimmed)) {
                    let match = /^https?:\/\/(\w+\.)?etherscan\.io\/(token|address)\/(0x[a-f0-9]{40})/i.exec(trimmed);
                    if (match) {
                        let network = match[1] ? match[1].slice(0, -1) : "mainnet";
                        let address = match[3];
                        if (address) {
                            this.addressFound({
                                address,
                                network,
                            });
                            return;
                        }
                    }
                    match =
                        /^https?:\/\/(app|qr)\.blockwell\.ai\/([a-zA-Z0-9]{6})(?![a-zA-Z0-9])/.exec(trimmed);
                    if (match) {
                        let shortcode = match[2];
                        this.api.getDumbapp(shortcode).then((dumbapp) => {
                            let step;
                            if (dumbapp.steps.length > 1) {
                                // TODO: This is obviously not ideal, but we need to decide somehow which one to use
                                // and typically with more than 2 steps it's 3 steps and the middle one is the right one.
                                // With 2 steps it's the last one, so the same number.
                                step = dumbapp.steps[1];
                            }
                            else {
                                step = dumbapp.steps[0];
                            }
                            this.addressFound({
                                address: step.address.parameters.value,
                                network: step.network.parameters.value,
                            });
                        });
                        return;
                    }
                }
            }
            this.$emit("reset");
        },
        resultValue(result) {
            let val = result.name;
            if (result.parameters?.symbol) {
                val += ` (${result.parameters.symbol})`;
            }
            return val;
        },
        addressFound(data) {
            if (this.loadContract) {
                this.doLoadContract(data);
            }
            else if (this.loadClick) {
                this.doLoadClick(data);
            }
            else {
                let link = this.loadLink(data);
                this.$router.push(link);
            }
        },
        loadLink(contract) {
            if (contract.bwns) {
                return `/${this.path}/${encodeURIComponent(contract.bwns)}`;
            }
            else {
                let link = `/${this.path}?`;
                if (contract.network) {
                    link += `net=${contract.network}&contract=${encodeURIComponent(contract.address)}`;
                }
                else {
                    link += `contract=${encodeURIComponent(contract.address)}`;
                }
                return link;
            }
        },
        networkName(network) {
            return getNetwork(network).networkName;
        },
        doLoadClick(data) {
            if (this.loadClick) {
                this.loadClick(data);
            }
            else if (this.loadContract) {
                this.doLoadContract(data);
            }
        },
        async doLoadContract(data) {
            this.loading = true;
            this.networkChoices = null;
            this.errorMessage = null;
            try {
                if (typeof data !== "string" && data.network) {
                    this.loadContract(await this.api.getContractId(data.address, data.network));
                }
                else {
                    let address;
                    if (typeof data === "string") {
                        address = data;
                    }
                    else {
                        address = data.address;
                    }
                    let result = await this.api.loadContract(address);
                    if (result.length === 1) {
                        this.loadContract(result[0]);
                    }
                    else if (result.length > 1) {
                        this.networkChoices = result;
                    }
                    else {
                        this.errorMessage = "No contracts found.";
                    }
                }
            }
            finally {
                this.loading = false;
            }
        },
        chooseNetwork(contract) {
            this.loadContract(contract);
            this.networkChoices = null;
        },
    },
};
