import {requireGuest} from "@/router/lib/router-utils";
import NotFound from "@/views/NotFound";

export default {
    routes: [
        {
            path: '/',
            name: "Defactor",
            component: () => import(/* webpackChunkName: "locks" */ '../views/happs/multichain/Multichain'),
            props: {
                name: "Defactor",
                symbol: "FACTR",
                decimals: 18,
                logo: require('@/assets/images/logos/defactor.png'),
                headingLogo: require('@/assets/images/logos/defactor_white.png'),
                icon: require('@/assets/images/logos/defactor_icon.png'),
                places: 2,
                chains: [
                    {
                        net: "mainnet",
                        address: "0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7"
                    },
                    {
                        net: "defactor",
                        address: "0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7",
                        suggestions: true,
                        staking: true
                    },
                    {
                        net: "bsc",
                        address: "0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7"
                    },
                ],
                stakingBook: "staking-factr",
                whitelabel: true,
                velvet: "defactor",
                external: "https://defactor.com/",
                whitelabelLayout: true,
                cssVars: {
                    "--body": "#ffffff",
                    "--body-text": "#000",
                    "--link": "#a2539b",
                    "--link-hover": "#b76cb0",
                    "--large-heading-background": "#000c22",
                    "--wallet-heading-background":
                        "#0f1629",
                    "--wallet-heading-text": "#fff",
                    "--primary": "#d32d7a",
                    "--primary-text": "#fff",
                    "--primary-muted": "#91184f",
                    "--primary-button": "#d32d7a",
                    "--primary-button-text": "#fff",
                    "--info-button": "#a2539b",
                    "--info-button-text": "#fff",
                    "--info-heading-background": "#f6eaea",
                    "--primary-muted-button": "#91184f",
                    "--primary-muted-button-text": "#fff",
                    "--font-family-body-text": "'Poppins', sans-serif",
                    "--font-family-button": "'Poppins', sans-serif",
                    "--font-family-heading": "'Poppins', sans-serif",
                    "--button-radius": "1px",
                    "--small-button-radius": "1px",
                    "--button-font-weight": 400,
                }
            }
        },
        {
            path: '/app/login',
            name: 'Login',
            beforeEnter: requireGuest,
            component: () => import(/* webpackChunkName: "login" */ '../views/app/Login')
        },
        {
            path: '*',
            beforeEnter(to, from, next) {
                window.location = "https://app.blockwell.ai" + to.fullPath;
            }
        }
    ]
}
