import Vue from 'vue';

export class TutorialState {
    /**
     * @type {number|null}
     */
    step = null;
    /**
     * @type {"new"|"triggered"|"skipped"|"completed"|"finished"}
     */
    status;
    /**
     * @type {boolean}
     */
    rewardShown;
    /**
     * @type {string}
     */
    id;

    /**
     * @param data
     * @return {TutorialState}
     */
    static from(data) {
        let state = new TutorialState();
        Object.assign(state, data);
        return state;
    }
}

export default {
    namespaced: true,
    state: {
        /**
         * @type Object<string,TutorialState>
         */
        tutorials: {}
    },
    mutations: {
        update_tutorial(state, tutorial) {
            Vue.set(state.tutorials, tutorial.id, tutorial);
        }
    },
    actions: {
        async getTutorialState({rootGetters, state, commit, dispatch}, id) {
            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];
            let data = await api.getTutorialState(id);
            let tutorial;

            if (data.status) {
                tutorial = TutorialState.from(data);
            } else {
                tutorial = TutorialState.from({
                    status: "new",
                    id
                })
            }

            commit('update_tutorial', tutorial);

            return tutorial;
        },
        async completeTutorial({rootGetters, state, commit}, id) {
            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];

            let tutorial = state.tutorials[id] || TutorialState.from({id});
            tutorial.status = "completed";

            commit("update_tutorial", tutorial);

            await api.updateTutorial(id, "completed");
        },
        async triggerTutorial({rootGetters, state, commit}, id) {
            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];

            let tutorial = state.tutorials[id] || TutorialState.from({id});
            tutorial.status = "triggered";

            commit("update_tutorial", tutorial);

            await api.updateTutorial(id, "triggered");
        },
        async skipTutorial({rootGetters, state, commit}, id) {
            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];

            let tutorial = state.tutorials[id] || TutorialState.from({id});
            tutorial.status = "skipped";

            commit("update_tutorial", tutorial);

            await api.updateTutorial(id, "skipped");
        },
        async tutorialRewardShown({rootGetters, state, commit}, id) {
            /**
             * @type {Api}
             */
            const api = rootGetters["user/api"];

            let tutorial = state.tutorials[id] || TutorialState.from({id, state: "rewarded"});
            tutorial.rewardShown = true;

            commit("update_tutorial", tutorial);

            await api.updateTutorial(id, null, true);
        }
    }
}
