import { cssVarDefaults } from "@/components/whitelabel/css";
import { reverse, uniq } from "rambdax";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        defaults: {
            style: {},
        },
        config: {
            style: {},
        },
        pages: {},
        showWhitelabel: {}
    },
    mutations: {
        update_defaults(state, config) {
            state.defaults = config;
        },
        save_config(state, config) {
            for (let [key, val] of Object.entries(config)) {
                if (key === "style") {
                    for (let [k, v] of Object.entries(val)) {
                        Vue.set(state.config.style, k, v);
                    }
                } else {
                    Vue.set(state.config, key, val);
                }
            }
        },
        replace_config(state, config) {
            state.config = config;
        },
        clear_config(state) {
            state.config = {
                style: {}
            };
        },
        add_page(state, { id, settings }) {
            Vue.set(state.pages, id, settings);
        },
        remove_page(state, id) {
            Vue.delete(state.pages, id);
        },
        show_whitelabel(state, { id, show }) {
            Vue.set(state.showWhitelabel, id, show);
        },
        remove_show_whitelabel(state, id) {
            Vue.delete(state.showWhitelabel, id);
        },
    },
    getters: {
        swatches(state) {
            let getColors = (style) => {
                return reverse(Object.values(style)).filter(
                    (it) => it.startsWith("rgba") || it.startsWith("#")
                );
            };
            let config = getColors(state.config.style || {});
            let defaults = getColors(state.defaults.style || {});
            let base = getColors(cssVarDefaults);

            return uniq([...config, ...defaults, ...base]);
        },
    },
    actions: {
        updateDefaults({ commit }, config) {
            commit("update_defaults", config);
        },
        saveWhitelabelConfig({ commit }, config) {
            commit("save_config", config);
        },
        replaceWhitelabelConfig({ commit }, config) {
            commit("replace_config", config);
        },
        clearWhitelabelConfig({ commit }) {
            commit("clear_config");
        },
        addPage({ commit }, { id, settings }) {
            commit("add_page", { id, settings });
        },
        showWhitelabel({commit}, {id, show}) {
            commit("show_whitelabel", { id, show });
        },
        removeShowWhitelabel({commit}, id) {
            commit("remove_show_whitelabel", id);
        }
    },
};
