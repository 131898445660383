<template>
    <div class="simplemde">
        <span v-if="showPlaceholder" class="empty-placeholder">{{ placeholder }}</span>
        <textarea class="book-editor md-editor"
                  ref="textarea">{{value}}</textarea>
    </div>
</template>

<script>
import Md from "@/assets/lib/md-extended";
import {debounce} from "@/lib/vutil";
import SimpleMDE from "@/views/happs/book/lib/simplemde/ionaru";
import '../lib/simplemde/simplemde.scss';
import 'codemirror/lib/codemirror.css';

export default {
    name: "MarkdownEditor",
    props: {
        content: String,
        readOnly: {
            type: Boolean,
            default: false
        },
        minHeight: {
            type: String,
            default: "300"
        },
        simpleToolbar: Boolean,
        noToolbar: Boolean,
        toolbar: Array,
        placeholder: String
    },
    data() {
        return {
            value: this.content,
            previewCache: '',
            isDestroyed: false
        };
    },
    computed: {
        showPlaceholder() {
            return this.placeholder && !this.value;
        }
    },
    watch: {
        content() {
            if (this.value !== this.content) {
                this.val(this.content);
                this.value = this.content;
            }
        }
    },
    methods: {
        val(content = null) {
            if (this.simplemde) {
                if (content === null) {
                    return this.simplemde.value();
                } else {
                    let value = this.simplemde.value();
                    if (value !== content) {
                        this.simplemde.value(content);
                    }
                }
            } else {
                return this.value;
            }
        },
        input(value) {
            this.$emit('input', value);
        },
        bookpage() {
            let newpath = this.$route.path.replace(/^\/book/, '/bookpage');
            this.$router.push(Object.assign({}, this.$route, {path: newpath}));
        },
        focus() {
            this.simplemde?.codemirror?.focus();
        },
        change(cm, event) {
            let value = this.simplemde.value();
            this.$emit('update:content', value);
            if (event.origin !== 'setValue') {
                this.input(value);
            }
        },
        blur(cm, event) {
            this.$emit('blur', cm, event);
        }
    },
    mounted() {
        Md()
            .then(async md => {
                if (this.isDestroyed) {
                    return;
                }
                const render = debounce((input, preview) => {
                    let rendered = md.render(input);
                    this.previewCache = rendered;
                    preview.innerHTML = rendered;
                }, 200);

                let toolbar;
                if (this.toolbar) {
                    toolbar = this.toolbar;
                } else if (this.noToolbar) {
                    toolbar = false;
                } else if (this.simpleToolbar) {
                    toolbar = [
                        'bold', 'italic', 'heading', '|', 'quote', 'unordered-list', 'ordered-list', '|',
                        'link', 'image', '|', 'side-by-side', '|', 'guide'
                    ];
                } else {
                    toolbar = [
                        'bold', 'italic', 'heading', '|', 'quote', 'unordered-list', 'ordered-list', '|',
                        'link', 'image', '|', 'preview', 'side-by-side', '|', 'guide',
                        {
                            name: "bookpage",
                            action: this.bookpage,
                            className: 'fa fa-book',
                            title: "BookPage Viewer"
                        }
                    ];
                }

                this.simplemde = new SimpleMDE({
                    element: this.$refs.textarea,
                    previewRender: (input, preview) => {
                        render(input, preview);
                        return this.previewCache;
                    },
                    minHeight: this.minHeight + "px",
                    status: false,
                    indentWithTabs: false,
                    toolbar,
                    sideBySideFullscreen: false
                });

                this.simplemde.codemirror.on('change', this.change);

                this.simplemde.codemirror.on('blur', this.blur);

                if (this.value) {
                    this.simplemde.value(this.value);
                }

                setTimeout(() => {
                    if (this.simplemde) {
                        this.simplemde.codemirror.refresh();
                    }
                }, 1000);
            })
            .catch(console.error);
    },
    beforeDestroy() {
        this.isDestroyed = true;
        if (this.simplemde) {
            this.simplemde.codemirror.off('change', this.change);
            this.simplemde.codemirror.off('blur', this.blur);
            this.simplemde.toTextArea();
            this.simplemde = null;
        }
    }
}
</script>

<style lang="scss">

@media screen and (max-width: 500px) {
    .simplemde .editor-toolbar button {
        width: 25px;
        height: 25px;
    }
}

</style>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.empty-placeholder {
    pointer-events: none;
    line-height: 20px;
    position: absolute;
    top: calc(50% - 10px);
    left: 5px;
    color: rgba($config, .5);
    font-style: italic;
    z-index: 1;
}
</style>
