import DumbappEmbed from "@/embed/views/DumbappEmbed";
import DumbappEmbedSubmission from "@/embed/views/DumbappEmbedSubmission";
import { props } from "@/router/lib/router-utils";

export default {
    base: "/embed/",
    routes: [
        {
            path: '/dumbapp/:shortcode([a-z0-9]{6})',
            component: DumbappEmbed,
            props,
        },
        {
            path: '/submission/:submissionId',
            name: 'Dumbapp Submission',
            component: DumbappEmbedSubmission,
            props,
        },
    ]
}
