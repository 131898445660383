import { getAddress } from "@blockwell/chain-client";
import { getChain } from "@blockwell/chains";
import { Dumbapp, DumbappSubmission } from "@blockwell/dumbapp";
import Api from "@/lib/api/WalletApi";
import { AddressCard, ContractAddress, WalletAddress } from "@/lib/rolodex/AddressCard";
import { mergeRolodexLog, RolodexLog, walApplyDelete, walApplySet } from "@/lib/rolodex/RolodexWal";
import RolodexWal, { RolodexLogDelete, RolodexLogSet } from "@/lib/rolodex/RolodexWal";
import { debounce } from "@/lib/vutil";
import { Happ } from "@/views/happs/Happ";
import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import mortice from "mortice";
import { delay, pick, sort } from "rambdax";

const mutex = mortice("rolodex_wal");

const channel = new BroadcastChannel("rolodex");
const elector = createLeaderElection(channel);

/**
 *
 * Cleans up an AddressCard for use with updates to specific properties.
 *
 * This only keeps properties that are needed for a WAL entry, so it removes
 * alias, contract, pinned and so on.
 *
 * @param {AddressCard} payload
 * @return {WalletAddress|ContractAddress}
 */
function convertPayload(payload) {
    let entry;

    // Pick to avoid saving extra properties
    if (payload.type === "contract") {
        entry = pick(["id", "type", "address", "chainId"], payload);
    } else {
        entry = pick(["id", "type", "address"], payload);
    }
    entry.timestamp = Date.now();

    return entry;
}

function rolodexDebug() {
    //console.log(...arguments);
}

export default {
    namespaced: true,
    state() {
        return {
            cards: [],
            walIndex: -1,
            wal: new RolodexWal(0),
            leader: false,
            flushing: false,
            syncing: false,
        };
    },
    getters: {
        addressCards(state) {
            return sort((a, b) => {
                if (a.pinned && !b.pinned) {
                    return -1;
                }
                if (b.pinned && !a.pinned) {
                    return 1;
                }
                return b.timestamp - a.timestamp;
            }, state.cards);
        },
        walletAddresses(state, getters) {
            return getters["addressCards"].filter((it) => it.type === "wallet");
        },
        contractAddresses(state, getters) {
            return getters["addressCards"].filter((it) => it.type === "contract");
        },
        recentWallets(state, getters) {
            return getters["walletAddresses"].slice(0, 5);
        },
        recentContracts(state, getters) {
            return getters["contractAddresses"].slice(0, 5);
        },
        allTags(state) {
            let tags = new Set();
            for (let it of state.cards) {
                if (it.tags) {
                    for (let tag of it.tags) {
                        if (tag) {
                            tags.add(tag);
                        }
                    }
                }
            }

            tags = Array.from(tags);
            tags.sort();
            return tags;
        }
    },
    mutations: {
        clear_internal(state) {
            state.cards = [];
            console.log("walIndex: clearing to -1");
            state.walIndex = -1;
            state.wal = new RolodexWal(0);
            state.leader = false;
            state.flushing = false;
            state.syncing = false;
        },
        /**
         * @param state
         * @param {AddressCard} payload
         */
        save_address(state, payload) {
            state.wal.set(payload);
        },
        /**
         * @param state
         * @param {AddressCard} payload
         */
        delete_address(state, payload) {
            state.wal.delete(payload);
        },
        /**
         * @param state
         * @param {RolodexLogSet} log
         */
        wal_apply_set(state, log) {
            walApplySet(state.cards, log);
        },
        /**
         * @param state
         * @param {RolodexLogDelete} log
         */
        wal_apply_delete(state, log) {
            walApplyDelete(state.cards, log);
        },
        /**
         * @param state
         * @param {number} index
         */
        wal_set_index(state, index) {
            console.log("walIndex: setting", index);
            state.walIndex = index;
        },
        /**
         * @param state
         * @param {number} toIndex
         */
        wal_flush_log(state, toIndex) {
            state.wal.flush(toIndex);
        },
        /**
         * @param state
         * @param { AddressCard[]} cards
         */
        replace_cards(state, cards) {
            state.cards = cards;
        },
        debug_leader(state, leadership) {
            state.leader = leadership;
        },
        debug_flushing(state, flushing) {
            state.flushing = flushing;
        },
        debug_syncing(state, syncing) {
            state.syncing = syncing;
        },
    },
    actions: {
        async clear({ commit }) {
            let release = await mutex.writeLock();
            try {
                await elector.awaitLeadership();
                commit("clear_internal");
            } finally {
                await elector.die();
                release();
            }
        },
        async fullFlush({dispatch}) {
            await dispatch("flushWal");
            dispatch("flushWalExternal");
        },
        async flushWal({ state, commit }) {
            let release = await mutex.writeLock();
            rolodexDebug("flush local");
            try {
                rolodexDebug("local waiting for leadership");
                await elector.awaitLeadership();
                let walIndex = -1;
                console.log("walIndex: flush local", state.walIndex);
                let useIndex = state.walIndex === -1 ? undefined : state.walIndex + 1;
                let logs = state.wal.getLogs(useIndex);
                for (let log of logs) {
                    switch (log.op) {
                        case "set":
                            commit("wal_apply_set", log);
                            break;
                        case "delete":
                            commit("wal_apply_delete", log);
                            break;
                    }
                    walIndex = log.index;
                }
                if (walIndex !== -1) {
                    commit("wal_set_index", walIndex);
                }
            } finally {
                rolodexDebug("local release");
                await elector.die();
                release();
            }
        },
        /**
         * This function will flush the WAL to an external data store.
         *
         * It acquires a local mutex first, and then creates a leadership election in case
         * there are other tabs. Once both have been acquired, it will send data to the external
         * store and flush the WAL.
         */
        flushWalExternal: debounce(async ({ state, rootGetters, commit }) => {
            if (!rootGetters["user/loggedIn"]) {
                return;
            }
            const api = rootGetters["user/api"];
            rolodexDebug("flushExternal");
            commit("debug_flushing", true);
            let release = await mutex.writeLock();
            try {
                rolodexDebug("waiting for leadership");
                await elector.awaitLeadership();
                commit("debug_leader", true);
                let walIndex = state.walIndex;
                let logs = state.wal.getLogs();
                // Don't flush logs that haven't been applied locally yet
                logs = logs.filter((it) => it.index <= walIndex);

                rolodexDebug("got leadership", logs);

                if (logs.length > 0) {
                    let compressed = [];

                    for (let log of logs) {
                        let existingIndex = compressed.findIndex((it) => it.id === log.id);

                        if (existingIndex > -1) {
                            compressed[existingIndex] = mergeRolodexLog(
                                compressed[existingIndex],
                                log
                            );
                        } else {
                            compressed.push(log);
                        }
                    }

                    compressed.sort((a, b) => a.index - b.index);

                    rolodexDebug("compressed", compressed);

                    await api.rolodex.applyWal(compressed);

                    let maxIndex = logs[logs.length - 1].index;

                    commit("wal_flush_log", maxIndex);

                    // Make sure mutations can propagate before continuing
                    await delay(2000);
                }
            } finally {
                rolodexDebug("release");
                await elector.die();
                release();
                commit("debug_leader", false);
                commit("debug_flushing", false);
            }
        }, 8000),
        /**
         * Sync an external Rolodex data source to local state.
         *
         * This works by replacing the current state with the state from the server,
         * and then replaying the WAL on top of it to merge updates.
         *
         * Afterwards, the WAL is flushed to the external database to persist
         * local changes.
         */
        async syncExternal({ state, rootGetters, commit, dispatch }) {
            if (!rootGetters["user/loggedIn"]) {
                return;
            }
            commit("debug_syncing", true);
            const api = rootGetters["user/api"];
            let release = await mutex.writeLock();
            try {
                await elector.awaitLeadership();
                commit("debug_leader", true);
                let cards = await api.rolodex.getAll();

                if (cards && cards.length > 0) {
                    let maxIndex;
                    for (let log of state.wal.getLogs()) {
                        switch (log.op) {
                            case "set":
                                walApplySet(cards, log);
                                break;
                            case "delete":
                                walApplyDelete(cards, log);
                                break;
                        }
                        maxIndex = log.index;
                    }
                    commit("replace_cards", cards);
                    if (maxIndex !== undefined) {
                        commit("wal_set_index", maxIndex);
                    }
                }
            } finally {
                await elector.die();
                commit("debug_leader", false);
                commit("debug_syncing", false);
                release();
            }

            dispatch("flushWalExternal");
        },
        /**
         *
         * @param state
         * @param commit
         * @param dispatch
         * @param {AddressCard} payload
         */
        saveAddress({ state, commit, dispatch }, payload) {
            rolodexDebug("rolodex saving address", payload);
            commit("save_address", {
                ...payload,
                address: getAddress(payload.address),
            });
        },
        /**
         * @param commit
         * @param dispatch
         * @param {AddressCard} payload
         */
        updateTimestamp({ commit, dispatch }, payload) {
            rolodexDebug("rolodex update timestamp", payload);
            let entry = convertPayload(payload);
            commit("save_address", entry);
            dispatch("fullFlush");
        },
        setAlias({ commit, dispatch }, { payload, alias, tags }) {
            rolodexDebug("rolodex set alias", payload, alias, tags);
            let entry = convertPayload(payload);
            entry.alias = alias;
            if (tags) {
                entry.tags = tags;
            }
            commit("save_address", entry);
            dispatch("fullFlush");
        },
        setPinned({ commit, dispatch }, { payload, pinned }) {
            rolodexDebug("rolodex set pinned", payload, pinned);
            let entry = convertPayload(payload);
            entry.pinned = pinned;
            commit("save_address", entry);
            dispatch("fullFlush");
        },
        delete({ commit, dispatch }, payload) {
            commit("delete_address", payload);
            dispatch("fullFlush");
        },
        /**
         *
         * @param state
         * @param dispatch
         * @param rootGetters
         * @param {Happ} happ
         */
        async saveHapp({ state, dispatch, rootGetters }, happ) {
            rolodexDebug("rolodex happ", happ.contractId, happ.network);
            if (happ.contractId && happ.network?.chainId) {
                let chainId = happ.network.chainId;
                let payload = {
                    id: chainId + "/" + happ.address.toLowerCase(),
                    type: "contract",
                    address: happ.address,
                    timestamp: Date.now(),
                    chainId,
                };
                await dispatch("saveAddress", payload);

                dispatch("fullFlush");
            }
        },
        /**
         * @param state
         * @param dispatch
         * @param rootGetters
         * @param {DumbappSubmission} submission
         */
        async saveDumbapp({ state, dispatch, rootGetters }, submission) {
            let flush = false;
            let dumbapp = submission.dumbapp;
            let i = 0;
            for (let it of dumbapp.steps) {
                let sub = submission.data.steps[i];
                if (it.method) {
                    flush = true;
                    let chainId = getChain(sub.network).chainId;
                    let payload = {
                        id: chainId + "/" + sub.address.toLowerCase(),
                        type: "contract",
                        address: sub.address,
                        timestamp: Date.now(),
                        chainId,
                    };
                    await dispatch("saveAddress", payload);
                }
                ++i;
            }

            if (flush) {
                dispatch("fullFlush");
            }
        },
        /**
         * Determines the type and properties of an address and then saves it.
         *
         * @param dispatch
         * @param {{ address: string, chainId?: number, exclude?: string[] }} payload
         * @return {Promise<void>}
         */
        async saveUnknownAddress(
            { dispatch },
            payload
        ) {
            rolodexDebug("rolodex save unknown", payload);
            await dispatch("_internalSaveUnknownAddress", payload);
            dispatch("fullFlush");
        },
        /**
         * @param dispatch
         * @param rootState
         * @param rootGetters
         * @param {{ address: string, chainId?: number, exclude?: string[] }} payload
         * @return {Promise<void>}
         */
        async _internalSaveUnknownAddress(
            { dispatch, rootState, rootGetters },
            payload
        ) {
            const account = rootState.user.account?.toLowerCase();
            const api = rootGetters["user/api"];
            rolodexDebug("rolodex internalSaveUnknown", payload);
            let { address, chainId, exclude } = payload;
            if (!exclude) {
                exclude = [];
            }
            const lower = address.toLowerCase();
            let unknown = false;

            rolodexDebug("rolodex internalSaveUnknown", lower, account, chainId);
            if (lower !== account && !exclude.includes(lower)) {
                try {
                    let result = await api.contracts.findByAddress(address);
                    rolodexDebug("rolodex loadContract result", result);
                    if (result && result.length > 0) {
                        let isContract = false;
                        let matched = false;
                        for (let it of result) {
                            if (it.wallet) {
                                let payload = {
                                    id: address.toLowerCase(),
                                    type: "wallet",
                                    address,
                                    timestamp: Date.now(),
                                };
                                await dispatch("saveAddress", payload);
                            } else if (it.id) {
                                isContract = true;
                                let contractChainId = getChain(it.network).chainId;
                                if (!chainId || contractChainId === chainId) {
                                    matched = true;
                                    let payload = {
                                        id: contractChainId + "/" + it.address.toLowerCase(),
                                        type: "contract",
                                        address: it.address,
                                        timestamp: Date.now(),
                                        chainId: contractChainId,
                                    };
                                    await dispatch("saveAddress", payload);
                                }
                            }
                        }

                        if (isContract && chainId && !matched) {
                            // If it is a contract address but there was no match, that probably
                            // means it wasn't deployed yet. We can add it as an unknown contract.
                            let payload = {
                                id: chainId + "/" + address.toLowerCase(),
                                type: "contract",
                                address: address,
                                chainId,
                                timestamp: Date.now(),
                            };
                            await dispatch("saveAddress", payload);
                        }
                    } else {
                        unknown = true;
                    }
                } catch (err) {
                    if (err.response?.data?.error?.code === "no_abi") {
                        unknown = true;
                    } else {
                        console.error(err);
                    }
                }
                if (unknown) {
                    // No results means we don't know if it's a wallet or contract.
                    // Save it as a wallet for now, but mark as "unknown".
                    let payload = {
                        id: address.toLowerCase(),
                        type: "wallet",
                        address,
                        timestamp: Date.now(),
                        unknown: true,
                    };
                    await dispatch("saveAddress", payload);
                }
            }
        },
        /**
         * @param dispatch
         * @param {DumbappSubmission} result
         * @return {Promise<void>}
         */
        async saveDumbappInput({ dispatch }, result) {
            const from = result.data.from?.toLowerCase();
            const chainId = getChain(result.data.steps[0].network).chainId;
            let flush = false;

            const add = async (address) => {
                flush = true;
                await dispatch("_internalSaveUnknownAddress", {
                    address,
                    chainId,
                    exclude: [from],
                });
            };

            let i = 0;
            for (let step of result.dumbapp.steps) {
                let args = result.data.args[i];

                if (args.address) {
                    await add(args.address);
                }

                let j = 0;
                for (let argument of step.arguments) {
                    if (argument.typing.name === "address") {
                        let val = args.args[j];
                        if (Array.isArray(val)) {
                            if (val.length < 4) {
                                for (let it of val) {
                                    await add(it);
                                }
                            }
                        } else {
                            await add(val);
                        }
                    }
                    ++j;
                }
                ++i;
            }

            if (flush) {
                dispatch("fullFlush");
            }
        },
    },
};
