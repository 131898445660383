<template>
    <layout-default>
        <div v-if="loadError">
            <portal to="title">
                <span>Not Found</span>
            </portal>
            <h1>404</h1>
            <p class="error">{{ loadError }}</p>
        </div>
        <div v-else class="dumbapp-page-wrap">
            <portal to="title">
                <span>{{ title }}</span>
                <button class="btn btn-tutorial desktop-tutorial" @click="showTutorial">
                    Tutorial
                </button>
            </portal>
            <div class="mobile-tutorial">
                <button class="btn btn-tutorial" @click="showTutorial">Tutorial</button>
            </div>
            <div ref="description">
                <Markdown v-if="description" :content="description" class="dumbapp-description" />
            </div>
            <div v-if="assetsBlast" class="dumbapp-description markdown-body">
                <p>
                    <strong>Limited Time Offer for SHOP holders</strong>. Your contribution will
                    grant you a 4:1 bonus in PRIDE token, up to the amount of your original Shopin
                    contribution.
                    <strong
                        >Act now as there is a HARD cap of $1 million USD total raise before this
                        offer is void</strong
                    >.
                </p>
                <p class="cap-remaining">
                    Cap Remaining: <span>${{ remaining }}</span>
                </p>
                <template v-if="contribReference">
                    <p v-if="assetsToken">
                        You can also <router-link :to="assetsEthLink">buy with ETH</router-link>, or
                        alternatively
                        <router-link :to="assetsQexLink">contribute via Quick Exchange</router-link>
                        using a VISA card.
                    </p>
                    <p v-else-if="assetsEth">
                        You can also
                        <router-link :to="assetsQexLink">contribute via Quick Exchange</router-link>
                        using a VISA card.
                    </p>
                    <p v-else>
                        Enter your desired amount of ETH, click Buy and Send, and you'll be prompted
                        for credit card details.
                    </p>
                </template>
            </div>
            <div v-if="contribAccount" class="contrib-account">
                <p>
                    In addition to using the form below, you can also just send tokens or Ether to
                    your unique personal contribution address:
                </p>
                <Clipboard :address="contribAccount" />
            </div>
            <DumbappBlocks />
            <div class="dumbapp-layout row align-items-start">
                <div v-show="showForm" class="dumbapp-form col-md-6" ref="formWrap">
                    <DumbappForm
                        v-if="dumbapp"
                        ref="form"
                        :dynamic="fields"
                        :form-data.sync="formData"
                    />
                </div>
                <div class="col-md-6" ref="preview">
                    <h5 class="transaction-preview-title">Transaction Preview</h5>
                    <DumbappPreview card :dumbapp="dumbapp" />
                </div>
            </div>
            <div class="button-wrap" ref="submit">
                <DumbappSubmit
                    :dumbapp="dumbapp"
                    :loading="submitting"
                    :disabled="submitting"
                    :network="network"
                    :disable-wallet="tutorialActive ? 'metamask' : null"
                    :strategy="strategy"
                    v-model="account"
                />
            </div>
            <router-link :to="creatorLink" class="creator-link">
                <i class="material-icons">edit</i>
                <span>Modify This bApp</span>
            </router-link>
            <a href="javascript: void 0;" class="embed-link" @click="openEmbed">
                <i class="material-icons">code</i>
                <span>Embed This bApp</span>
            </a>
            <Modal :show.sync="showEmbed" id="dumbapp-embed-modal">
                <template #header>
                    <h5>Embed bApp</h5>
                </template>
                <p>
                    The following code lets you <strong>embed</strong> this bApp as a
                    <strong>simple button </strong>that can be used with MetaMask.
                </p>
                <CodeBlock :embed-code="embedCode" />
                <p>
                    You can
                    <a href="https://docs.blockwell.ai/wallet/bapp-metamask.html" target="_blank">
                        read more about these embeds in our documentation</a
                    >.
                </p>
            </Modal>

            <div class="tutorial-steps" style="display: none">
                <div ref="tutorialIntro">
                    <div class="text-center">
                        <img src="../../assets/images/tutor.png" width="96" />
                        <h3>bApp Tutorial</h3>
                    </div>
                    <p>
                        This tutorial will show you how <strong>Blockwell Dapps</strong>, or
                        <strong>bApps</strong>
                        for short, work.
                    </p>
                    <p>
                        If you complete the tutorial, you will be
                        <strong>rewarded some tokens</strong>!
                    </p>
                </div>
                <div ref="tutorialAbout">
                    <p>
                        <strong>bApps</strong> are a kind of <strong>dapp</strong> that make it easy
                        to use any smart contract on any compatible blockchain.
                    </p>
                    <p>
                        All bApps submit one or more <strong>transactions</strong> on the
                        blockchain. Transactions are how you interact with smart contracts.
                    </p>
                    <p>Let's take a closer look at the different parts of a bApp.</p>
                </div>
                <div ref="tutorialDescription">
                    <p>
                        At the top is the <strong>description</strong> that tells you in general
                        terms what the bApp does.
                    </p>
                </div>
                <div ref="tutorialForm">
                    <p>
                        The <strong>form</strong> is for filling in any information required for the
                        transaction.
                    </p>
                </div>
                <div ref="tutorialNoForm">
                    <p>
                        bApps can have a
                        <strong>form</strong> for filling in any information required for the
                        transaction.
                    </p>
                    <p>
                        This bApp does not have a form, but when there is one, it will be shown
                        above, or next to, the Transaction Preview.
                    </p>
                </div>
                <div ref="tutorialPreview">
                    <p>
                        The <strong>Transaction Preview</strong> shows you the exact details to be
                        submitted to the blockchain.
                    </p>
                </div>
                <div ref="tutorialWallet">
                    <p>
                        This shows you the <strong>wallet</strong> you'll be using for the
                        transaction.
                    </p>
                    <p v-if="!loggedIn">
                        Since you are <strong>not signed in</strong>, it's not showing your wallet
                        address yet.
                    </p>
                </div>
                <div ref="tutorialDifferentWallet">
                    <p>
                        bApps can also be used with MetaMask.
                        <strong>Try clicking to change your wallet</strong>.
                    </p>
                </div>
                <div ref="tutorialSelectWallet">
                    <p>
                        We generally recommend using Blockwell Wallet as it is the simplest to use,
                        but MetaMask allows you to utilize your existing wallet if you have one.
                    </p>
                    <p><strong>Select Blockwell Wallet to continue</strong>.</p>
                </div>
                <div ref="tutorialSubmit">
                    <p>Finally, when you're ready to submit the transaction, click <i>Send</i>.</p>
                </div>
                <div ref="tutorialLogin">
                    <p>
                        This would be the end of the tutorial, but you must be signed in to finish
                        it and earn a reward.
                    </p>
                    <p>
                        <strong>If you don't have an account</strong>, simply enter an email and a
                        password to register.
                    </p>
                    <LoginForm @login="tutorialLogin" />
                </div>
                <div ref="tutorialEnd">
                    <p>
                        To complete this tutorial
                        <strong
                            >submit any bApp! After submission, we'll send you some tokens as a
                            reward.</strong
                        >
                    </p>
                    <p>
                        You can submit the one you're viewing currently, or you can
                        <router-link to="/izfd2j" @click.native="completeDumbappTutorial"
                            >try this sample one</router-link
                        >
                        for something easy to submit.
                    </p>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import CodeBlock from "@/components/CodeBlock";
import DumbappBlocks from "@/components/dumbapp/block/DumbappBlocks";
import DumbappForm from "@/components/dumbapp/DumbappForm";
import DumbappMixin from "@/components/dumbapp/DumbappMixin";
import DumbappPreview from "@/components/dumbapp/DumbappPreview";
import SubmissionsList from "@/components/dumbapp/SubmissionsList";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit";
import LoadingButton from "@/components/LoadingButton";
import LoginForm from "@/components/LoginForm";
import Markdown from "@/components/Markdown";
import Modal from "@/components/Modal";
import { Chain } from "@blockwell/chain-client";
import bookAbi from "@/assets/data/BaseBook.abi.json";
import TutorialMixin from "@/lib/TutorialMixin";
import { Dumbapp } from "@blockwell/dumbapp";
import { stringifyQuery } from "@blockwell/util";
import BigNumber from "bignumber.js";
import Shepherd from "shepherd.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import "shepherd.js/dist/css/shepherd.css";
import bootbox from "bootbox";

export default {
    name: "DumbappPage",
    components: {
        CodeBlock,
        DumbappBlocks,
        LoginForm,
        Clipboard,
        DumbappSubmit,
        LoadingButton,
        Modal,
        SubmissionsList,
        Markdown,
        DumbappForm,
        DumbappPreview,
    },
    mixins: [DumbappMixin, TutorialMixin],
    props: {
        query: Object,
    },
    data() {
        return {
            tutorial: null,
            tutorialActive: false,
            showEmbed: false,
            account: null,
            embedArgs: ""
        };
    },
    computed: {
        ...mapGetters("user", ["api", "loggedIn"]),
        loadError() {
            let err = this.dumbappError;
            if (err) {
                if (err.response?.status === 404) {
                    return "Dumbapp not found, double check your link.";
                }
            }
        },
        showForm() {
            return this.dumbapp?.dynamic?.length > 0;
        },
        creatorLink() {
            return `/creator/${this.dumbapp?.shortcode}`;
        },
        contribReference() {
            return this.query.i;
        },
        assetsBlast() {
            return (
                this.dumbapp &&
                (this.dumbapp.uniqueReference?.endsWith("-assets-blast") ||
                    this.dumbapp.shortcode === "4hx4yo" ||
                    this.dumbapp.shortcode === "bh8s4y")
            );
        },
        assetsToken() {
            return this.dumbapp?.uniqueReference?.endsWith("-assets-blast");
        },
        assetsEth() {
            return this.dumbapp?.shortcode === "4hx4yo";
        },
        assetsEthLink() {
            return `/4hx4yo?i=${this.contribReference}`;
        },
        assetsQexLink() {
            return `/bh8s4y?i=${this.contribReference}`;
        },
        embedCode() {
            return `<div class="dumbappembed-container"
    data-label="${this.title}"
    data-shortcode="${this.dumbapp?.shortcode}"
    data-args="${this.embedArgs}"></div>
<script src="https://cdn.blockwell.ai/dumbappembed/bundle.js" />
<link rel="stylesheet" type="text/css" href="https://cdn.blockwell.ai/dumbappembed/main.css" />`;
        },
        contribDeps() {
            return {
                ref: this.contribReference,
                account: this.account,
                loggedIn: this.$store.getters["user/loggedIn"],
            };
        },
    },
    asyncComputed: {
        contribAccount() {
            if (this.contribReference) {
                return this.api.contribAccount(this.contribReference);
            }
            return null;
        },
        async remaining() {
            if (this.assetsBlast) {
                let json = await Chain.readString(
                    5,
                    "0xaEEeBd6ca46BEf69689F043681fEe5F9961D199A",
                    bookAbi,
                    "get",
                    ["Assets", "Data"],
                    120000
                );

                let data = JSON.parse(json);

                return new BigNumber(data.remaining).toFormat();
            }
            return null;
        },
    },
    watch: {
        assetsBlast: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.$router.replace(`/offer/${this.contribReference}`);
                }
            },
        },
        contribDeps: {
            deep: true,
            handler(contrib) {
                if (contrib.ref && contrib.account && !contrib.loggedIn) {
                    this.api.contribWalletLoaded(contrib.ref, contrib.account).catch(console.error);
                }
            },
        },
        dumbapp: {
            immediate: true,
            handler(val) {
                if (val) {
                    document.title = val.getTitle() + " | Blockwell Wallet";
                }
            }
        }
    },
    methods: {
        showTutorial() {
            this.destroyTutorial();

            this.tutorial = new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {
                    cancelIcon: {
                        enabled: true,
                    },
                    arrow: false,
                    modalOverlayOpeningPadding: 5,
                    modalOverlayOpeningRadius: 5,
                    buttons: [
                        {
                            text: "Next",
                            action() {
                                this.next();
                            },
                        },
                    ],
                    popperOptions: {
                        modifiers: [{ name: "offset", options: { offset: [0, 5] } }],
                    },
                    scrollTo: {
                        behavior: "smooth",
                        block: "center",
                    },
                },
            });

            this.tutorial.addSteps([
                {
                    text: this.$refs.tutorialIntro,
                    buttons: [
                        {
                            text: "Skip",
                            secondary: true,
                            action() {
                                this.cancel();
                            },
                        },
                        {
                            text: "Start Tutorial",
                            action() {
                                this.next();
                            },
                        },
                    ],
                },
                {
                    text: this.$refs.tutorialAbout,
                },
                {
                    text: this.$refs.tutorialDescription,
                    attachTo: {
                        element: this.$refs.description,
                        on: "auto-end",
                    },
                },
                {
                    text: this.$refs.tutorialForm,
                    attachTo: {
                        element: this.$refs.formWrap,
                        on: "auto",
                    },
                    showOn: () => {
                        return this.showForm;
                    },
                },
                {
                    text: this.$refs.tutorialPreview,
                    attachTo: {
                        element: this.$refs.preview,
                        on: "auto",
                    },
                },
                {
                    text: this.$refs.tutorialNoForm,
                    showOn: () => {
                        return !this.showForm;
                    },
                },
                {
                    text: this.$refs.tutorialWallet,
                    attachTo: {
                        element: ".wallet-type",
                        on: "auto",
                    },
                },
                {
                    text: this.$refs.tutorialDifferentWallet,
                    attachTo: {
                        element: ".change-wallet",
                        on: "bottom",
                    },
                    buttons: [],
                    advanceOn: {
                        selector: ".change-wallet a",
                        event: "click",
                    },
                },
                {
                    text: this.$refs.tutorialSelectWallet,
                    attachTo: {
                        element: ".wallet-type-modal",
                        on: "auto",
                    },
                    advanceOn: {
                        selector: ".wallet-type-wallet",
                        event: "click",
                    },
                    buttons: [],
                    scrollTo: false,
                },
                {
                    text: this.$refs.tutorialSubmit,
                    attachTo: {
                        element: ".dumbapp-submit-buttons",
                        on: "bottom",
                    },
                    canClickTarget: false,
                },
                {
                    text: this.$refs.tutorialLogin,
                    showOn: () => {
                        return !this.loggedIn;
                    },
                    buttons: [],
                },
                {
                    text: this.$refs.tutorialEnd,
                    buttons: [
                        {
                            text: "OK",
                            action() {
                                this.complete();
                            },
                        },
                    ],
                },
            ]);

            this.tutorial.on("complete", () => {
                this.completeTutorial("dumbapp");
            });
            this.tutorial.on("cancel", () => {
                this.skipTutorial("dumbapp");
            });
            this.tutorial.on("show", () => {
                this.tutorialActive = true;
            });
            this.tutorial.on("hide", () => {
                this.tutorialActive = false;
            });
            this.tutorial.show();
        },
        destroyTutorial() {
            if (this.tutorial) {
                this.tutorial.off("complete");
                this.tutorial.off("cancel");
                this.tutorial.off("show");
                this.tutorial.off("hide");

                if (this.tutorial) {
                    this.tutorial.off;
                    for (let it of this.tutorial.steps) {
                        it.destroy();
                    }
                    this.tutorial.hide();
                }
            }
        },
        completeDumbappTutorial() {
            this.tutorial?.complete();
        },
        tutorialLogin() {
            this.tutorial.next();
        },
        openEmbed() {
            if (this.embedArgs !== null) {
                this.showEmbed = true;
            } else {
                bootbox.alert({
                    title: "Fill In Form",
                    message: `You must fill in all required fields in the form to create an embed.`,
                    backdrop: true,
                });
            }
        },
        /**
         * @param {ResolveParameters} params
         */
        embedChanges(params) {
            if (params.success === true) {
                for (let it of params.arguments) {
                    if (it.missing) {
                        this.embedArgs = null;
                        return;
                    }
                }
                let combined = params.values.combineInputs();
                this.embedArgs = stringifyQuery(combined);
            }
        }
    },
    mounted() {
        if (this.loggedIn) {
            this.getTutorialState("dumbapp").then((it) => {
                if (it.status === "new") {
                    this.triggerTutorial("dumbapp");
                } else if (it.status === "triggered") {
                    this.showTutorial();
                }
            });
        }
        this.provider.execution.onResult(this, (result) => {
            if (result.success === true) {
                this.$router.push({
                    name: "Dumbapp Submissions",
                    params: {
                        submissionId: result.data.submission.id,
                    },
                });
            }
        });
        this.provider.on("resolve", this.embedChanges);
        this.hidden = false;
    },
    beforeDestroy() {
        this.provider.execution.removeHandler(this);
        this.provider.off("resolve", this.embedChanges);
    },
    destroyed() {
        this.destroyTutorial();
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.dumbapp-form {
    margin-top: 15px;
}

.transaction-preview-title {
    margin-top: 0;
}

.button-wrap {
    margin-top: 10px;
}

.dumbapp-description {
    ::v-deep( a.header-anchor ) {
        display: none;
    }
}

.dumbapp-page-wrap {
    position: relative;
}

.creator-link,
.embed-link {
    float: right;
    width: 80px;
    text-align: center;
    font-size: 10px;

    &:hover {
        text-decoration: none;
    }

    ::v-deep( i ) {
        font-size: 20px;
        display: block;
        color: rgba($primary, 0.5);
    }
}

.contrib-account {
    padding: 15px;
    margin: 20px 0;
    border: 1px solid $primary;
    border-radius: 4px;

    ::v-deep( .clipboard-wrap ) {
        font-weight: 600;
        display: block;
        max-width: 100%;

        ::v-deep( span ) {
            display: block;
            overflow-wrap: break-word;
        }
    }

    @media screen and (max-width: 440px) {
        ::v-deep( .clipboard-wrap ) {
            font-size: 0.9em;
        }
    }
    @media screen and (max-width: 380px) {
        ::v-deep( .clipboard-wrap ) {
            font-size: 0.85em;
            overflow-wrap: break-word;
        }
    }
}

.cap-remaining {
    font-size: 20px;

    ::v-deep( span ) {
        color: $code-color;
    }
}

.desktop-tutorial {
    margin-left: 25px;
    @media screen and (max-width: 640px) {
        display: none;
    }
}

.mobile-tutorial {
    position: absolute;
    top: -34px;
    width: 56px;
    overflow: hidden;
    right: -16px;

    .btn {
        padding: 4px 4px;
    }

    @media screen and (min-width: 641px) {
        display: none;
    }
}

#dumbapp-embed-modal ::v-deep( textarea ) {
    font-family: monospace;
    font-size: 12px;
}
</style>
