import FuelTicketRow from "@/views/happs/fuel/FuelTicketRow.vue";
export default {
    name: "FuelTicketsList",
    components: { FuelTicketRow },
    data() {
        return {
            list: []
        };
    },
    methods: {},
    created() {
        const api = this.$store.getters["user/api"].fuel;
        api.getTickets().then(({ data, products }) => {
            this.list = data.map(it => {
                return {
                    ...it,
                    product: products.find(prod => prod.id === it.productId)
                };
            });
        });
    },
};
