import FuelHistoryRow from "@/views/happs/fuel/FuelHistoryRow.vue";
import Pagination from "@/components/Pagination.vue";
export default {
    name: "FuelHistoryList",
    components: { Pagination, FuelHistoryRow },
    data() {
        return {
            list: [],
            paging: { count: 0, limit: 20, start: 0 },
        };
    },
    computed: {
        page: {
            get() {
                const paging = this.paging;
                return 1 + Math.floor((paging.start / paging.limit));
            },
            set(val) {
                const paging = this.paging;
                paging.start = (val - 1) * paging.limit;
            }
        },
        pages() {
            const paging = this.paging;
            return Math.ceil(paging.count / paging.limit);
        }
    },
    watch: {
        "paging.start"() {
            this.update();
        },
        "paging.limit"() {
            this.update();
        }
    },
    methods: {
        update() {
            const paging = this.paging;
            const api = this.$store.getters["user/api"].fuel;
            api.getHistory(paging.start, paging.limit).then(({ data, paging }) => {
                this.paging.count = paging.count;
                this.list = data;
            });
        },
    },
    created() {
        this.update();
    }
};
