<template>
    <layout-default no-title>
        <portal to="title">
            <span>Blockwell Apps</span>
        </portal>
        <ProgressCircle v-if="$asyncComputed.apps.updating"/>
        <section class="apps-list">
            <article v-for="app in apps" class="blockwell-app" @click="openApp(app)">
                <div class="app-logo">
                    <img :src="app.logo" alt=""/>
                </div>
                <h4>{{ app.title }}</h4>
                <p>{{ app.description }}</p>
            </article>
        </section>
    </layout-default>
</template>

<script>
import ProgressCircle from "@/components/ProgressCircle";
import {loadAppList} from "@/lib/blockwell-apps";

export default {
    components: {ProgressCircle},
    data() {
        return {};
    },
    methods: {
        openApp(app) {
            if (!/^https?:\/\//.test(app.url)) {
                this.$router.push(app.url);
            } else {
                window.location = app.url;
            }
        }
    },
    asyncComputed: {
        apps() {
            return loadAppList();
        }
    }
}
</script>

<style scoped lang="scss">

.apps-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.blockwell-app {
    flex: 0 0 250px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;

    ::v-deep( h4 ) {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .app-logo {
        width: 250px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ::v-deep( img ) {
        width: 60px;
    }


    p {
        font-size: 15px;
        text-align: center;
        margin: 15px 0 8px;
    }
}
</style>
