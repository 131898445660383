<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>Not Found</span>
        </portal>

        <h3>Page Not Found</h3>
        <p>This page does not exist, double check your link.</p>
    </layout-heading>
</template>

<script>
    export default {
        name: "NotFound",
        data() {
            return {};
        }
    }
</script>
