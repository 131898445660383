<template>
    <layout-heading size="medium">
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>Create Token</span>
        </portal>
        <p>
            Blockwell's <a href="https://docs.blockwell.ai/contracts/prime.html" target="_blank">Prime Token</a>
            is a multi-functional ERC-20 compatible token with advanced features.
        </p>
        <template v-if="submitted">
            <h3>Request Submitted</h3>
            <p>
                Thank you, we will contact you by email within the next few days with further details regarding your
                ERC-20 token generation.
            </p>
        </template>
        <template v-else>
            <p>
                Fill in the form below and we'll get back to you with the specifics.
            </p>

            <FormulateForm
                v-model="formData"
                @submit="submit"
                @validation="validation = $event">
                <h4>Your Information</h4>
                <FormulateInput
                    type="text"
                    name="name"
                    label="Name"
                    validation="required"
                    class="width-5"
                    help="Enter your name."
                />
                <FormulateInput
                    type="email"
                    name="email"
                    label="Email Address"
                    validation="required|email"
                    class="width-4"
                    help="Enter your email address where we can contact you."
                    :value="email"
                />
                <FormulateInput
                    type="text"
                    name="organization"
                    label="Organization"
                    class="width-5"
                    help="Optionally enter the name of the organization the token is for."
                />
                <h4>Token Information</h4>
                <p>You can fill these in now to speed up the process, or leave them empty.</p>
                <label>Token Name and Symbol</label>
                <div class="token-name-symbol">
                    <FormulateInput
                        type="text"
                        name="tokenName"
                        help="Token name."
                    />
                    <FormulateInput
                        type="text"
                        name="symbol"
                        help="Token symbol."
                    />
                </div>
                <FormulateInput
                    type="number"
                    name="supply"
                    label="Total Number of Tokens"
                    class="width-3"
                    help="Optional. Enter the total token supply for the token."
                />

                <h4>Fee Structure</h4>
                <p>
                    Select the fee structure you would like to use. Upfront Price is paid in Ether, and the percentage is
                    taken from the total supply of tokens.
                </p>

                <div class="fee-options">
                    <div v-for="(opt, index) in fees"
                         class="fee-option"
                         :class="selectedFee === index ? 'selected' : ''"
                         @click="selectedFee = index"
                    >
                        <div class="upfront">
                            <label>Upfront Price</label>
                            <span class="fee-value">{{ formatPrice(opt.price) }}</span>
                        </div>
                        <div class="percentage">
                            <label>Percentage</label>
                            <span class="fee-value">{{ opt.percentage }} %</span>
                            <span class="fee-preview"
                                  v-if="formatShare(opt.percentage)"> = {{ formatShare(opt.percentage) }} {{ formData.symbol }}</span>
                        </div>
                    </div>
                </div>

                <p class="error">{{ submitError }}</p>
                <LoadingButton :disabled="validation.hasErrors" :loading="submitting" small @click="submit">
                    Submit
                </LoadingButton>
            </FormulateForm>
        </template>
    </layout-heading>
</template>

<script>
import LoadingButton from "@/components/LoadingButton";
import {mapGetters, mapState} from "vuex";
import BigNumber from "bignumber.js";

export default {
    components: {LoadingButton},
    data() {
        return {
            formData: {},
            validation: {},
            submitting: false,
            submitted: false,
            submitError: null,
            selectedFee: 0,
            fees: [
                {
                    price: 20000,
                    percentage: 0.5
                },
                {
                    price: 10000,
                    percentage: 0.66
                },
                {
                    price: 7500,
                    percentage: 0.75
                },
                {
                    price: 5000,
                    percentage: 1
                },
                {
                    price: 2500,
                    percentage: 1.25
                },
                {
                    price: 1000,
                    percentage: 2
                }
            ]
        };
    },
    computed: {
        ...mapState('user', ['email']),
        ...mapGetters('user', ['api'])
    },
    methods: {
        formatPrice(price) {
            return '$' + new BigNumber(price).toFormat();
        },
        formatShare(perc) {
            if (this.formData.supply) {
                let share = new BigNumber(this.formData.supply).times(perc).div(100);

                if (!share.isNaN()) {
                    return share.toFormat();
                }
            }
            return null;
        },
        submit() {
            this.submitting = true;
            let fees = this.fees[this.selectedFee];
            this.api.primeSubmission({
                ...this.formData,
                fees: this.formatPrice(fees.price) + " - " + fees.percentage + "%"
            })
                .catch(err => this.submitError = err.message)
                .then(() => {
                    this.submitted = true;
                    this.$router.push({
                        hash: '#submitted'
                    })
                })
                .finally(() => this.submitting = false);
        }
    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.token-name-symbol {
    display: flex;

    > ::v-deep(div:last-child ){
        margin-left: 5px;
        width: 100px;
    }
}

.fee-options {
    max-width: 360px;
}

.fee-option {
    cursor: pointer;
    padding: 10px 15px;
    margin: 8px 0;
    border: 1px solid rgba($text-muted, .3);
    border-radius: 5px;

    &.selected {
        border-color: $primary;
        background: $light;
    }
}

.upfront, .percentage {
    ::v-deep() {
        label {
            width: 140px;
            font-weight: normal;
        }

        .fee-value {
            font-weight: 600;
        }

        .fee-preview {
            color: $secondary;
        }
    }
}
</style>
