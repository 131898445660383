import Api from "@/lib/api/WalletApi";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        addresses: []
    },
    getters: {
        addresses(state) {
            return state.addresses.filter(it => typeof it === "string");
        },
        scopedAddresses(state) {
            return state.addresses.filter(it => it.address && it.scope);
        }
    },
    mutations: {
        add_address(state, address) {
            if (typeof address === "string") {
                let index = state.addresses.indexOf(address.toLowerCase());
                if (index === -1) {
                    state.addresses.unshift(address.toLowerCase());
                }
            } else {
                let addresses = state.addresses.filter(it => it.address && it.scope === address.scope);
                let lower = address.address.toLowerCase();
                if (!addresses.find(it => it.address === lower)) {
                    state.addresses.unshift({
                        address: lower,
                        scope: address.scope
                    });
                }
            }
        },
        remove_address(state, address) {
            let addr = typeof address === "string" ? address : address.address;
            let scope = address.scope;
            let lower = addr.toLowerCase();
            let index;
            if (scope) {
                index = state.addresses.findIndex(it => it.scope === scope && it.address === lower);
            } else {
                index = state.addresses.findIndex(it => it === lower);
                if (index === -1) {
                    index = state.addresses.findIndex(it => it === addr);
                }
            }
            if (index !== -1) {
                state.addresses.splice(index, 1);
            }
        }
    }
}
