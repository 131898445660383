export default {
    namespaced: true,
    state: {
        status: "ok",
        message: null,
        error: null
    },
    mutations: {
        save_status(state, {status}) {
            state.status = status.status;
            state.message = status.message;
            state.error = status.error;
        }
    },
    actions: {
        async update({commit, rootGetters}) {
            /**
             * @type {Api}
             */
            let api = rootGetters["user/api"];
            let status = await api.getStatus();
            if (status) {
                commit('save_status', {status});
            }
        }
    }
}
