import {requireAuth, requireGuest} from "./lib/router-utils";

let routes = [
    {
        path: '/app/account',
        name: 'My Account',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/Account')
    },
    {
        path: '/app/account/kyc',
        name: 'KYC',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/Kyc')
    },
    {
        path: '/app/account/kyc/external',
        name: 'KYC External Wallets',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/External'),
        props: {
            kyc: true
        }
    },
    {
        path: '/app/account/kyc/details',
        name: 'KYC Details',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/KycDetails')
    },
    {
        path: '/app/account/external',
        name: 'External Wallets',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/External')
    },
    {
        path: '/app/account/apiminer',
        name: 'API Miner Keys',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/ApiMiner')
    },
    {
        path: '/app/account/chronica',
        name: 'Chronica',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/chronica/Chronica')
    },
    {
        path: '/app/account/contracts',
        name: 'My Contracts',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/DeployedContracts')
    },
    {
        path: '/app/account/emojicryption',
        name: 'Emojicryption Settings',
        component: () => import(/* webpackChunkName: "account" */ '../views/app/account/EmojicryptionSettings')
    },
    {
        path: '/app/account/qc',
        redirect: '/app/account/emojicryption'
    }
];

routes.forEach(it => {
    if (it.beforeEnter === undefined) {
        it.beforeEnter = requireAuth;
    }
});

routes.push({
    path: '/app/account/submissions',
    name: "Dumbapp Submissions",
    component: () => import(/* webpackChunkName: "account" */ '../views/app/account/Submissions')
});

export default routes;
