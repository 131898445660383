<template>
    <layout-default>
        <div class="row align-items-start">
            <MessagesForSubmission :submission="loadedSubmission" class="submission-page-messages col-12"/>
            <div class="col-lg-6 col-md-8">
                <div class="dumbapp-page-submission card">
                    <SubmissionHeader
                        :expanded="true"
                        :submission="loadedSubmission"
                    />
                    <div v-if="steps">
                        <DumbappPreviewStep v-for="(step, i) in steps"
                                     :step="step.dumbapp"
                                     :submission-step="step.submission"
                                     :index="i"
                                     :key="step.submission.id"
                                     class="step"/>
                    </div>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
import MessagesForSubmission from "@/components/dumbapp/confirmation/MessagesForSubmission";
import DumbappPreviewStep from "@/components/dumbapp/DumbappPreviewStep";
import Submission from "@/components/dumbapp/Submission";
import SubmissionHeader from "@/components/dumbapp/SubmissionHeader";
import SubmissionMixin from "@/components/dumbapp/SubmissionMixin";
import FadeSlide from "@/components/FadeSlide";

export default {
    name: "DumbappSubmissionPage",
    components: {MessagesForSubmission, SubmissionHeader, DumbappPreviewStep, FadeSlide, Submission},
    mixins: [SubmissionMixin]
}
</script>

<style lang="scss">
@import "~@/assets/css/custom";

.submission-page-messages {
    margin-bottom: 20px;

    .dumbapp-confirmation-message {
        @include mdElevation(2);
        padding: 16px;
    }
}

.dumbapp-page-submission {
    padding: 0;
}
</style>
