import {requireAuth, requireGuest} from "./lib/router-utils";

let routes = [
    {
        path: '/app/login',
        name: 'Login',
        beforeEnter: requireGuest,
        component: () => import(/* webpackChunkName: "login" */ '../views/app/Login')
    },
    {
        path: '/app/forgot',
        name: 'Forgot Password',
        beforeEnter: requireGuest,
        component: () => import(/* webpackChunkName: "login" */ '../views/app/Forgot')
    },
    {
        path: '/app/verify/:token',
        name: 'Verify',
        beforeEnter: null,
        component: () => import(/* webpackChunkName: "login" */ '../views/app/Verify')
    },
    {
        path: '/app/wallet',
        name: 'Blockwell Wallet',
        component: () => import(/* webpackChunkName: "wallet" */ '../views/app/Wallet')
    },
    {
        path: '/app/wallet/scanner',
        name: 'Scanner',
        component: () => import(/* webpackChunkName: "wallet" */ '../views/app/Scanner')
    },
    {
        path: '/app/wallet/send',
        name: 'Send Tokens',
        component: () => import(/* webpackChunkName: "wallet" */ '../views/app/Send')
    },
    {
        path: '/app/wallet/transactions/:id',
        name: 'Transaction',
        component: () => import(/* webpackChunkName: "transaction" */ '../views/app/Transaction')
    },
    {
        path: '/app/wallet/code/:shortcode',
        redirect: to => {
            return {
                path: `/${to.params.shortcode}`,
                query: to.query,
                hash: to.hash
            }
        }
    },
    {
        path: '/app/wallet/code/transaction/:id',
        redirect: to => {
            return {
                path: `/app/transaction/${to.params.id}`,
                query: to.query,
                hash: to.hash
            }
        }
    }
];

routes.forEach(it => {
    if (it.beforeEnter === undefined) {
        it.beforeEnter = requireAuth;
    }
});

export default routes;
