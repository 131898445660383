import dayjs from "dayjs";
export default {
    name: "FuelTicketRow",
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {};
    },
    computed: {
        created() {
            const item = this.item;
            if (item?.created) {
                return dayjs(item.created).format("lll");
            }
            return null;
        },
    },
};
