import * as push from './push';

let pushWorker = require('file-loader?{"name":"[name].[ext]","publicPath":"/"}!./push-worker');

export function isPushEnabled() {
    navigator.serviceWorker.register(pushWorker);

    return navigator.serviceWorker.ready
        .then(registration => {
            return push.isEnabled(registration);
        });
}

export function enablePush(api) {
    navigator.serviceWorker.register(pushWorker);

    return navigator.serviceWorker.ready
        .then(registration => {
            return push.enable(api, registration);
        });
}

export function disablePush(api) {
    navigator.serviceWorker.register(pushWorker);

    return navigator.serviceWorker.ready
        .then(registration => {
            return push.disable(api, registration);
        });
}
