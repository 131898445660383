import { FuelData } from "@/lib/fuel";
import dayjs from "dayjs";
import ProgressCircle from "@/components/ProgressCircle.vue";
import { mapGetters } from "vuex";
export default {
    name: "FuelBalance",
    components: { ProgressCircle },
    props: {
        balanceWhole: String,
        balanceDecimals: String,
        fuel: FuelData,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters("dumbapp", ["tracking"]),
        tracked() {
            let tracks = this.tracking["fuel"];
            if (tracks) {
                return (tracks.filter((it) => {
                    return it.status === "pending" ||
                        (dayjs().diff(dayjs(it.submission.created), "minutes") < 3 &&
                            this.fuel.stake.lte(it.stake));
                }).length > 0);
            }
            return false;
        },
    },
    mounted() {
        let tracks = this.tracking?.["fuel"];
        if (tracks) {
            for (let track of tracks) {
                if (track.status !== "pending" &&
                    dayjs().diff(dayjs(track.submission.created), "minutes") >= 3) {
                    this.$store.dispatch("dumbapp/removeTracking", {
                        key: "fuel",
                        id: track.id,
                    });
                }
            }
        }
    },
};
