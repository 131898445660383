import Api from "@/lib/api/WalletApi";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        drafts: {}
    },
    mutations: {
        save_draft(state, {key, value}) {
            Vue.set(state.drafts, key, value);
        },
        clear_draft(state, key) {
            Vue.delete(state.drafts, key);
        }
    },
    getters: {
    },
    actions: {
        saveTaskDraft({commit}, {contractId, title, description, reward, rewardToken}) {
            commit("save_draft", {
                key: `${contractId}-create-task`,
                value: {
                    title, description, reward, rewardToken
                }
            });
        },
        clearTaskDraft({commit}, contractId) {
            commit("clear_draft", `${contractId}-create-task`);
        },
        saveCommentDraft({commit}, {contractId, taskId, text, type}) {
            commit("save_draft", {
                key: `${contractId}-comment-${taskId}`,
                value: {
                    text,
                    type
                }
            });
        },
        clearCommentDraft({commit}, {contractId, taskId}) {
            commit("clear_draft", `${contractId}-comment-${taskId}`);
        },
        saveNftDraft({commit}, {contractId, name, description, image, attributes}) {
            commit("save_draft", {
                key: `${contractId}-mint`,
                value: {
                    name, description, image, attributes
                }
            });
        },
        clearNftDraft({commit}, contractId) {
            commit("clear_draft", `${contractId}-mint`);
        },
        saveDraft({commit}, {key, value}) {
            commit("save_draft", {key, value});
        },
        clearDraft({commit}, key) {
            commit("clear_draft", key);
        },
    }
}
