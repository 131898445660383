import store from '../../store/index';

export function requireAuth(to, from, next) {
    if (store.getters["user/loggedIn"]) {
        next();
    } else {
        next('/app/login?redirect=' + encodeURIComponent(to.fullPath));
    }
}

export function requireGuest(to, from, next) {
    if (store.getters["user/loggedIn"]) {
        next('/app/wallet');
    } else {
        next();
    }
}

export function props(route) {
    return {
        ...route.params,
        query: route.query,
        hash: route.hash
    }
}
