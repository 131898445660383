import dayjs from "dayjs";
import BigNumber from "bignumber.js";
export default {
    name: "FuelHistoryRow",
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {};
    },
    computed: {
        referenceLink() {
            const item = this.item;
            if (item.reference) {
                if (item.productId === "dumbapp") {
                    return `/${item.reference}`;
                }
                else if (item.productId === "palette") {
                    return `/palette2/${item.reference}`;
                }
            }
            return null;
        },
        negative() {
            const item = this.item;
            return new BigNumber(item?.amount).lt(0);
        },
        timestamp() {
            const item = this.item;
            if (item?.timestamp) {
                return dayjs(item.timestamp).format("lll");
            }
            return null;
        },
        groupName() {
            const item = this.item;
            switch (item?.type) {
                case "trial":
                case "fuel":
                    return "Fuel";
                case "ticket_purchase":
                case "ticket_use":
                    return "Ticket";
                case "subscription_start":
                case "subscription_renew":
                case "subscription_restart":
                case "subscription_cancel":
                case "subscription_suspend":
                case "subscription_end":
                    return "Subscription";
                default:
                    return "";
            }
        },
        typeName() {
            const item = this.item;
            switch (item?.type) {
                case "trial":
                    return "Trial";
                case "fuel":
                    return "Add Fuel";
                case "ticket_purchase":
                    return "Purchase";
                case "ticket_use":
                    return "Use";
                case "subscription_start":
                    return "Start";
                case "subscription_renew":
                    return "Renew";
                case "subscription_restart":
                    return "Restart";
                case "subscription_cancel":
                    return "Cancel";
                case "subscription_suspend":
                    return "Suspend";
                case "subscription_end":
                    return "End";
                default:
                    return "";
            }
        }
    },
    methods: {}
};
