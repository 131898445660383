import Clipboard from "@/components/Clipboard.vue";
import CodeBlock from "@/components/CodeBlock.vue";
import ColorField from "@/components/color/ColorField.vue";
import ColorPicker from "@/components/color/ColorPicker.vue";
import DumbappForm from "@/components/dumbapp/DumbappForm.vue";
import DumbappMixin from "@/components/dumbapp/DumbappMixin";
import DumbappPreview from "@/components/dumbapp/DumbappPreview.vue";
import SubmissionsList from "@/components/dumbapp/SubmissionsList.vue";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit.vue";
import Markdown from "@/components/Markdown.vue";
import Modal from "@/components/Modal.vue";
import WhitelabelColor from "@/components/whitelabel/WhitelabelColor.vue";
import { renderQr } from "@/lib/qrcode";
import { ExecutionStateValueProvider } from "@blockwell/dumbapp";
import { debounce, stringifyQuery } from "@blockwell/util";
import { mapGetters } from "vuex";
export default {
    components: {
        ColorField,
        ColorPicker,
        WhitelabelColor,
        CodeBlock,
        DumbappSubmit,
        Clipboard,
        Modal,
        SubmissionsList,
        Markdown,
        DumbappForm,
        DumbappPreview,
    },
    mixins: [DumbappMixin],
    props: {
        shortcode: String,
        query: Object,
    },
    data() {
        return {
            color: "#004FFF",
            style: "Centered",
            logo: "https://cdn.blockwell.ai/assets/bwqr.png",
            label: "",
            embedArgs: null,
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
        image() {
            return this.dumbapp?.image;
        },
        url() {
            if (this.dumbapp) {
                const url = new URL(window.location.href);
                let link = url.protocol + "//" + url.host + this.dumbapp.url;
                if (this.embedArgs) {
                    link += "?" + this.embedArgs;
                }
                return link;
            }
            return "";
        },
        embedUrl() {
            if (this.dumbapp) {
                const url = new URL(window.location.href);
                let link = url.protocol + "//" + url.host + "/embed/dumbapp" + this.dumbapp.url;
                if (this.embedArgs) {
                    link += "?" + this.embedArgs;
                }
                return link;
            }
            return "";
        },
        embedCode() {
            return `<iframe width="420" height="600" src="${this.embedUrl}"></iframe>`;
        },
        metamaskEmbed() {
            return `<div class="dumbappembed-container"
    data-label="${this.title}"
    data-shortcode="${this.dumbapp?.shortcode}"
    data-args="${this.embedArgs}"></div>
<script src="https://cdn.blockwell.ai/dumbappembed/bundle.js" />
<link rel="stylesheet" type="text/css" href="https://cdn.blockwell.ai/dumbappembed/main.css" />`;
        },
        qrDeps() {
            return {
                shortcode: this.dumbapp?.shortcode,
                form: this.formData,
                color: this.color,
                logo: this.logo,
                overlay: this.style === "Overlay",
                label: this.label,
            };
        },
    },
    watch: {
        dumbapp: {
            immediate: true,
            handler(val) {
                if (val) {
                    document.title = val.getTitle() + " | Blockwell Wallet";
                }
            },
        },
        qrDeps: {
            immediate: true,
            deep: true,
            handler: debounce(function (val) {
                if (val.shortcode) {
                    renderQr(this.$refs.canvas, val.shortcode, val.form, val.color, val.logo, val.overlay, val.label);
                }
            }, 100, { maxWait: 200 }),
        },
    },
    methods: {
        embedChanges(params) {
            if (params.success === true && params.values instanceof ExecutionStateValueProvider) {
                for (let it of params.arguments) {
                    if (it.missing) {
                        this.embedArgs = null;
                        return;
                    }
                }
                let combined = params.values.combineInputs();
                this.embedArgs = stringifyQuery(combined);
            }
        },
    },
    mounted() {
        const provider = this.provider;
        provider.execution.onResult(this, async (result) => {
            if (result.success === true) {
                this.$router.push({
                    name: "Dumbapp Submissions",
                    params: {
                        submissionId: result.data.submission.id,
                    },
                });
            }
        });
        provider.on("resolve", this.embedChanges);
        this.hidden = false;
    },
    beforeDestroy() {
        this.provider.execution.removeHandler(this);
        this.provider.off("resolve", this.embedChanges);
    },
};
