<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>Pay Deposit</span>
        </portal>
        <template v-if="notFound">
            <h3>Deposit Information Not Found</h3>
            <p>
                Check your link. Contact us if this issue persists.
            </p>
        </template>
        <template v-else-if="deposit">
            <p class="pay-deposit">
                Blockwell Token Deposit for deploying
                <strong v-if="tokenName">{{ tokenName }}</strong>
                <span v-else>a Prime token</span>
                <strong>{{ tokenSymbol }}</strong>
                <template v-if="supply"> with a total supply of {{ supply }}</template>
                .
            </p>
            <p class="pay-deposit">
                The deposit is <strong>{{price}}</strong> paid in ETH, which is 10% of the total {{totalPrice}} fee. The remaining
                {{remaining}} and {{deposit.data.percentage}}% Token Supply Fee will be paid to the smart contract that creates your token.
            </p>
            <p class="pay-deposit">
                After the deposit, you will receive a link to deploy your token within 24 hours.
            </p>

            <h3>Choose Your Payment Method</h3>
            <div class="payment-methods">
                <div class="method">
                    <h3>
                        <img src="../assets/images/logos/metamask-fox.svg" alt="" width="50"/>
                        MetaMask
                    </h3>
                    <p>
                        Pay using a MetaMask wallet.
                    </p>
                    <div class="eth-amount">
                        {{ ethFormatted }}
                    </div>

                    <div class="eth-dumbapp">
                        <DumbappPopover
                            shortcode="ethapp"
                            label="Pay Deposit"
                            :values="dumbappQuery"
                        />
                    </div>
                </div>

                <div class="method">
                    <h3>
                        <img src="../assets/images/icons/token-creator.svg" alt="" width="45"
                             style="margin-right: 5px;"/>
                        Any Wallet
                    </h3>
                    <p>
                        Pay using any wallet.
                    </p>
                    <div class="eth-amount">
                        {{ ethFormatted }}
                    </div>

                    <div class="any-wallet-description">
                        Send the shown amount of ETH from your existing wallet or exchange account to the following
                        address.
                    </div>

                    <Clipboard :address="deposit.account" class="contrib-address"/>
                </div>
            </div>
        </template>
    </layout-heading>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import DumbappPopover from "@/components/DumbappPopover";
import LoadingButton from "@/components/LoadingButton";
import {ETH_ADDRESS, getPrice} from "@/lib/prices";
import {mapGetters, mapState} from "vuex";
import BigNumber from "bignumber.js";

export default {
    components: {DumbappPopover, Clipboard, LoadingButton},
    props: {
        reference: String
    },
    data() {
        return {
            deposit: null,
            notFound: false
        };
    },
    computed: {
        ...mapGetters('user', ['api']),
        price() {
            if (this.deposit) {
                return '$' + new BigNumber(this.deposit.amount).toFormat() + " USD";
            }

            return "";
        },
        totalPrice() {
            if (this.deposit) {
                return '$' + new BigNumber(this.deposit.data.fee).toFormat() + " USD";
            }

            return "";
        },
        remaining() {
            if (this.deposit) {
                return '$' + new BigNumber(this.deposit.data.fee).minus(this.deposit.amount).toFormat() + " USD";
            }

            return "";
        },
        eth() {
            if (this.deposit && this.ethPrice) {
                return new BigNumber(this.deposit.amount).div(this.ethPrice).sd(3, BigNumber.ROUND_CEIL);
            }

            return null;
        },
        ethFormatted() {
            return this.eth?.toFormat() + " ETH"
        },
        tokenName() {
            return this.deposit?.data.tokenName;
        },
        tokenSymbol() {
            let sym = this.deposit?.data.symbol;
            if (sym) {
                return ` (${sym})`;
            }
            return '';
        },
        supply() {
            let supply = this.deposit?.data.supply;
            if (supply) {
                return new BigNumber(supply).toFormat() + ' ' + (this.deposit?.data.symbol || 'tokens');
            }
            return null;
        },
        dumbappQuery() {
            return {
                account: this.deposit?.account,
                value: this.eth.toString(10)
            }
        }
    },
    asyncComputed: {
        ethPrice() {
            return getPrice(ETH_ADDRESS);
        }
    },
    watch: {
        reference: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.api.getDeposit(val).then(it => this.deposit = it)
                        .catch(err => {
                            this.notFound = true;
                        })
                } else {
                    this.deposit = null;
                }
            }
        }
    },
    methods: {}
}
</script>

<style scoped lang="scss">
@use "sass:color";
@import "~@/assets/css/custom.scss";

.pay-deposit {
    font-size: 20px;
}

$border: color.scale($text-muted, $lightness: 50%);

.payment-methods {
    display: grid;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 820px) {
        display: block;
    }

    ::v-deep( .method ) {
        padding: 45px;
        border-left: 1px solid $border;
        border-top: 1px solid $border;

        @media screen and (max-width: 920px) {
            padding: 40px 30px;
        }

        &:nth-child(2n) {
            border-right: 1px solid $border;
        }

        &:last-child {
            border-bottom: 1px solid $border;
        }

        &:nth-last-child(2) {
            border-bottom: 1px solid $border;
        }

        @media screen and (max-width: 820px) {
            border: none !important;
            max-width: 420px;
            margin: 20px auto;
            padding: 15px;
        }

        h3 {
            font-size: 32px;
            line-height: 60px;
            text-align: center;

            img {
            }
        }

        p {
            font-size: 18px;
            text-align: center;
            margin-top: 15px;
        }

        @media screen and (max-width: 440px) {
            flex: 0 0 100%;
        }
    }
}

.contrib-address {
    border: 1px solid rgba($primary, .3);
    @include mdElevation(1);
    padding: 10px;
    margin-top: 4px;

    @media screen and (max-width: 880px) {
        font-size: 14px;
    }
    @media screen and (max-width: 374px) {
        font-size: 12px;
    }
    @media screen and (max-width: 330px) {
        font-size: 11px;
    }
}

.eth-amount {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin: 15px 0;
}

.any-wallet-description {
    margin: 8px 0;
}

.eth-dumbapp {
    display: flex;
    justify-content: center;
}
</style>
