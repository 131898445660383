export default {
    namespaced: true,
    state: {
        messages: [],
        unreadCount: 0,
        count: 0
    },
    mutations: {
        save_messages(state, {messages, unreadCount, count}) {
            state.messages = messages;
            state.unreadCount = unreadCount;
            state.count = count;
        },
        remove_message(state, message) {
            state.messages = state.messages.filter(it => it.id !== message.id);
        },
        clear_messages(state) {
            state.messages = [];
            state.unreadCount = 0;
            state.count = 0;
        }
    },
    actions: {
        async update({commit, rootGetters}) {
            let loggedIn = rootGetters["user/loggedIn"];
            /**
             * @type {Api}
             */
            let api = rootGetters["user/api"];

            if (loggedIn) {
                let {status, messages} = await api.getMessages();
                commit({
                    type: "status/save_status",
                    status
                }, {root: true});

                commit('save_messages', messages);
            } else {
                commit('clear_messages');
            }
        },
        async markAsRead({state, commit, rootGetters}) {
            if (state.messages.length === 0 || state.unreadCount < 1) {
                return;
            }

            /**
             * @type {Api}
             */
            let api = rootGetters["user/api"];

            let messages = await api.readMessages(state.messages[0].t);
            commit('save_messages', messages);
        },
        async dismiss({state, commit, rootGetters}, message) {
            commit('remove_message', message);
            /**
             * @type {Api}
             */
            let api = rootGetters["user/api"];

            let messages = await api.dismissMessage(message.id);
            commit('save_messages', messages);
        }
    }
}
