import { cssVarDefaults } from "@/components/whitelabel/css";
import { reverse, uniq } from "rambdax";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
            options: {
                view: {
                    default: {
                        extraSpacing: true,
                        outlines: true,
                        conditionBlocks: true,
                        conditionStripes: true,
                        selection: true,
                    },
                    preview: {
                        extraSpacing: false,
                        outlines: false,
                        conditionBlocks: false,
                        conditionStripes: false,
                        selection: true,
                    }
                }
            }
    },
    mutations: {
        update_option(state, {name, value}) {
            let curr = state.options;
            let parts = name.split(".");

            while (parts.length > 1) {
                curr = curr[parts.shift()];
            }

            curr[parts[0]] = value;
        },
    },
};
