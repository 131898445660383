import Bwns from "@/lib/api/Bwns";
import Dapp from "@/assets/lib/Dapp";
import { getNetwork } from "@/assets/lib/networks";
import Recent from "@/lib/Recent";
import { mapActions } from "vuex";
import Vue from "vue";
import "vue-router";
import DataLoader from "@/lib/data/DataLoader";
import { addressEqual, chainEqual } from "@blockwell/chains";
const recent = new Recent();
export class Happ {
    static async load(api, address, network, id, alternative = false) {
        if (!address) {
            throw new Error("Cannot load a contract without an address.");
        }
        let contractId = id;
        let _network = network;
        if (!_network || !contractId) {
            try {
                let result = await api.getContractId(address, _network);
                if ("network" in result) {
                    _network = result.network;
                    contractId = result.id;
                }
            }
            catch (err) {
                console.error("Failed to get contract ID", err);
            }
        }
        // No network, can't proceed
        if (!_network) {
            throw new Error("No contract found for address.");
        }
        let net = Dapp.getNetwork(_network, alternative);
        let happ = new Happ(net.web3, getNetwork(_network), address, contractId);
        happ.cache = {};
        return Object.freeze(happ);
    }
    static async loadRecents(type, deployerAddress) {
        let deployer = await recent.getDeployerRecent(type, deployerAddress);
        return {
            deployer,
            user: recent.getUserRecent(type),
        };
    }
    /**
     *
     * @param {Web3} web3
     * @param {EthNetwork} network
     * @param {string} address
     * @param {string?} id
     */
    constructor(web3, network, address, id) {
        this.cache = {};
        //this.web3 = web3;
        this.network = network;
        this.address = address;
        this.contractId = id;
    }
    /**
     *
     * @return {Web3}
     */
    get web3() {
        let net = Dapp.getNetwork(this.network);
        return net.web3;
    }
}
const bwns = new Bwns();
export default Vue.extend({
    props: {
        name: String,
    },
    data() {
        return {
            contract: {
                address: null,
                network: null,
                contractId: null,
            },
            bwns: null,
            happError: null,
            happ: null,
            happLoading: false,
        };
    },
    methods: {
        ...mapActions("dumbapp", ["trackSubmission"]),
        async loadHapp(address, network, id, alternative = false) {
            let addr = address;
            let net = network;
            let contractId = id;
            let beforeLoad = this.beforeLoadHapp;
            if (beforeLoad) {
                let vals = await beforeLoad(address, net, id);
                if (vals.address !== undefined) {
                    addr = vals.address;
                }
                if (vals.network !== undefined) {
                    net = vals.network;
                }
                if (vals.contractId !== undefined) {
                    contractId = vals.contractId;
                }
            }
            let happ = await Happ.load(this.api, addr, net, contractId, alternative);
            this.$store.dispatch("rolodex/saveHapp", happ).catch(console.error);
            return happ;
        },
        track(key, data) {
            this.trackSubmission({
                key: [this.type, getNetwork(this.network).networkId, this.address, key].join("-"),
                data,
            });
        },
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        bookHelper() {
            return this.$store.getters["user/bookHelper"];
        },
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
        account() {
            return this.$store.state.user.account;
        },
        address() {
            return this.happ?.address;
        },
        network() {
            return this.happ?.network?.chainId;
        },
        contractId() {
            return this.happ?.contractId;
        },
        contractInfo() {
            return {
                bwnsName: this.name,
                type: this.type,
                address: this.$route.query.contract,
                network: this.$route.query.net,
            };
        },
        loading() {
            return this.happLoading || this.loader?.loadingData;
        }
    },
    destroyed() {
        this.loader?.destroy();
    },
    watch: {
        happ: {
            immediate: true,
            handler(val) {
                if (this.loaderFunction) {
                    if (this.onData) {
                        this.onData(null);
                    }
                    if (this.loader) {
                        let loader = this.loader;
                        loader.destroy();
                    }
                    if (val) {
                        let loader = new DataLoader(this.api, this.bookHelper, val, {
                            loader: this.loaderFunction,
                            account: this.account,
                            onData: async (data) => {
                                if (this.onData) {
                                    await this.onData(data);
                                }
                            },
                            dumbappsUrl: this.dumbappsUrl,
                            onDumbapps: (dumbapps) => {
                                if (this.onDumbapps) {
                                    this.onDumbapps(dumbapps);
                                }
                            },
                        });
                        this.loader = loader;
                        loader
                            .load()
                            .then((contract) => {
                            recent.addUserRecent(this.type, {
                                name: contract.name,
                                address: contract.address,
                                network: contract.network,
                                bwns: this.bwns?.name,
                            });
                            if (this.onLoad) {
                                this.onLoad(contract);
                            }
                        })
                            .catch((err) => console.error(err));
                        loader.startUpdates();
                    }
                }
            },
        },
        contract: {
            deep: true,
            immediate: true,
            async handler(val) {
                if (this.happ &&
                    addressEqual(this.happ.address, val.address) &&
                    chainEqual(this.happ.network, val.network)) {
                    return;
                }
                let happ;
                if (val.address) {
                    this.happLoading = true;
                    try {
                        happ = await this.loadHapp(val.address, val.network, val.contractId);
                    }
                    catch (err) {
                        console.error(err);
                        this.happError = err.message;
                        return;
                    }
                    finally {
                        this.happLoading = false;
                    }
                }
                else {
                    happ = null;
                }
                this.happ = happ;
                if (happ) {
                    this.happError = null;
                    if (!this.bwns ||
                        !addressEqual(happ.address, this.bwns.address) ||
                        !chainEqual(happ.network, this.bwns.network)) {
                        let ns = await bwns.forContractAddress(this.type, this.happ.address, this.happ.network.chainId);
                        if (ns) {
                            this.bwns = ns;
                            await this.$router.replace({
                                path: `/${this.type}/${ns.name}`,
                            });
                        }
                    }
                }
            },
        },
        contractInfo: {
            deep: true,
            immediate: true,
            async handler(val) {
                if (val.bwnsName) {
                    if (!this.bwns || this.bwns.name !== val.bwnsName) {
                        this.happLoading = true;
                        let ns;
                        try {
                            let res = await bwns.resolve(val.bwnsName, val.type);
                            if ("network" in res) {
                                ns = res;
                            }
                        }
                        finally {
                            this.happLoading = false;
                        }
                        if (!ns) {
                            this.happError = `Contract for ${this.type} with the name "${val.bwnsName}" was not found.`;
                            this.contract = {
                                address: null,
                                network: null,
                                contractId: null,
                            };
                        }
                        else {
                            this.happError = null;
                            this.bwns = ns;
                            this.contract = {
                                address: ns.address,
                                network: ns.network,
                                contractId: ns.contractId,
                            };
                        }
                    }
                }
                else if (val.address) {
                    this.happError = null;
                    this.bwns = null;
                    this.contract = {
                        address: val.address,
                        network: val.network,
                        contractId: null,
                    };
                }
                else {
                    this.happError = null;
                    this.bwns = null;
                    this.contract = {
                        address: null,
                        network: null,
                        contractId: null,
                    };
                }
            },
        },
    },
});
