<template>
    <layout-heading no-messages>
        <portal to="title">
            <img src="../assets/images/icons/pride.svg" alt="">
            <span>Contribute</span>
        </portal>
        <h2 class="choose-method">Choose Your Contribution Method</h2>

        <template v-if="reference">
            <p class="choose-method-subtitle">
                Which ever method you use, your contribution will grant you a {{ multiplier }}:1 bonus in PRIDE token
                while allocation lasts!
            </p>

            <div class="contrib-choices">
                <MetaMaskOffer
                    :reference="reference"
                    :multiplier="multiplier"/>
                <AnyWalletOffer
                    :multiplier="multiplier"
                    :contrib="contrib"/>
                <VisaOffer
                    :reference="reference"
                    :multiplier="multiplier"/>
                <BitcoinOffer
                    :multiplier="multiplier"/>
            </div>
        </template>
        <template v-else>
            <p class="choose-method-subtitle">Enter your email address to get started.</p>
            <div class="email-form">
                <FormulateForm
                    @submit="submitEmail">
                    <FormulateInput
                        type="email"
                        label="Email Address"
                        validation="required|email"
                        v-model="email"
                        @validation="validation = $event"
                    />
                    <p class="error">{{ submitError }}</p>
                    <LoadingButton :disabled="validation.hasErrors" :loading="submitting" small @click="submitEmail">
                        Submit
                    </LoadingButton>
                </FormulateForm>
            </div>
        </template>
    </layout-heading>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import DumbappPopover from "@/components/DumbappPopover";
import LoadingButton from "@/components/LoadingButton";
import AnyWalletOffer from "@/components/offer/AnyWalletOffer";
import BitcoinOffer from "@/components/offer/BitcoinOffer";
import MetaMaskOffer from "@/components/offer/MetaMaskOffer";
import VisaOffer from "@/components/offer/VisaOffer";
import {getPrice} from "@/lib/prices";
import ContractLoader from "@/views/happs/components/ContractLoader";
import BigNumber from "bignumber.js";
import {mapGetters} from "vuex";

export default {
    components: {
        LoadingButton,
        VisaOffer, BitcoinOffer, AnyWalletOffer, MetaMaskOffer, DumbappPopover, ContractLoader, Clipboard
    },
    props: {
        reference: String
    },
    data() {
        return {
            visaAmount: '',
            email: '',
            validation: {},
            submitting: false,
            submitError: null
        };
    },
    computed: {
        ...mapGetters('user', ['api']),
        contrib() {
            return this.contribData?.address;
        },
        multiplier() {
            return parseFloat(this.contribData?.multiplier || 4);
        }
    },
    asyncComputed: {
        contribData() {
            if (this.reference) {
                return this.api.contribAccount(this.reference);
            }
            return null;
        }
    },
    methods: {
        submitEmail() {
            this.submitting = true;
            this.submitError = null;
            this.api.createContribAccount(this.email)
                .then(it => {
                    return this.$router.push(`/offer/${it.reference}`);
                })
                .catch(err => {
                    console.error(err);
                    this.submitError = "An error occurred, please try again later.";
                })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }
}
</script>

<style scoped lang="scss">
@use "sass:color";
@import "~@/assets/css/custom.scss";

.choose-method {
    font-size: 38px;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
}

.choose-method-subtitle {
    font-size: 20px;
    letter-spacing: 0.5px;
    text-align: center;
    max-width: 820px;
    margin: 0 auto;
}

$border: color.scale($text-muted, $lightness: 50%);

.contrib-choices {
    display: grid;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 820px) {
        display: block;
    }

    ::v-deep( .contrib-method ) {
        padding: 45px;
        border-left: 1px solid $border;
        border-top: 1px solid $border;

        @media screen and (max-width: 920px) {
            padding: 40px 30px;
        }

        &:nth-child(2n) {
            border-right: 1px solid $border;
        }

        &:last-child {
            border-bottom: 1px solid $border;
        }
        &:nth-last-child(2) {
            border-bottom: 1px solid $border;
        }

        @media screen and (max-width: 820px) {
            border: none !important;
            max-width: 420px;
            margin: 0 auto;
            padding: 15px;
        }

        h3 {
            font-size: 32px;
            line-height: 60px;
            text-align: center;

            img {
            }
        }

        p {
            font-size: 18px;
            text-align: center;
            margin-top: 15px;
        }

        @media screen and (max-width: 440px) {
            flex: 0 0 100%;
        }
    }
}

.email-form {
    max-width: 400px;
    margin: 0 auto;
}

</style>
