import DumbappPopover from "@/components/DumbappPopover.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import BigNumber from "bignumber.js";
import Vue from "vue";
import DumbappModal from "@/components/DumbappModal.vue";
export default Vue.extend({
    name: "InfoField",
    components: { DumbappModal, DumbappPopover, TokenAmount },
    props: {
        value: [String, BigNumber, Number],
        label: String,
        usd: Boolean,
        symbol: String,
        small: Boolean,
        dumbapp: {
            type: Object
        },
        query: {
            type: Object
        }
    },
    inject: {
        token: {
            default: null,
        },
    },
    computed: {
        usdFormatted() {
            if (this.value && this.token?.price) {
                return "$" + this.token.price.times(this.value).div(`1e${this.token.decimals}`).toFormat(2);
            }
            return '';
        }
    }
});
