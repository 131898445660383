<template>
    <layout-heading no-messages>
        <portal to="title">
            <img src="../../../assets/images/icons/fire.svg" alt="" />
            <span>Blockwell Fuel</span>
        </portal>

        <nav v-if="loggedIn" class="fuel-tabs nav-buttons">
            <a
                v-for="it in tabs"
                class="nav-button"
                :href="'#' + it"
                :class="{ active: active === it }"
                >{{ it }}</a
            >
        </nav>
        <div
            v-if="active === 'Balance'"
            class="fuel-balance-content"
            :class="{ 'buy-active': showBuy }"
        >
            <div v-if="loggedIn" class="buy-fuel-wrapper">
                <FuelBalance
                    :fuel="fuel"
                    :balance-whole="balanceWhole"
                    :balance-decimals="balanceDecimals"
                />
                <template v-if="showBuy">
                    <div class="back-to-tokens">
                        <router-link to="/fuel">&laquo; Back To Tokens</router-link>
                    </div>
                    <BuyFuelWith :account="account" :fuel="fuel" :token="token" />
                </template>
                <FuelTokenSelect v-else @token="tokenSelected" />
            </div>
            <div v-else>
                <h3>Sign In to use Fuel</h3>
                <LoginForm />
            </div>
            <div id="what-is-fuel">
                <h3>
                    <strong>Blockwell Fuel</strong> is a token you use to pay for Blockwell products
                    and services.
                </h3>
                <h4>No Fees</h4>
                <p>Fuel runs on Blockwell's Firechain so it has no fees*.</p>
                <h4>Instant Payments</h4>
                <p>
                    Through a staking mechanism, payments are instant and reconciled on the
                    blockchain later.
                </p>
                <p class="footnote">
                    <small
                        >* Ethereum gas fees apply when buying Fuel, but not when using it.</small
                    >
                </p>
            </div>
        </div>
        <FuelSubscriptionsList v-else-if="active === 'Subscriptions'" />
        <FuelHistoryList v-else-if="active === 'History'" />
        <FuelTicketsList v-else-if="active === 'Tickets'" />
    </layout-heading>
</template>

<script>
import ProgressCircle from "@/components/ProgressCircle";
import { FuelData, fuelTokenAddress, fuelTokenNetwork, loadFuel } from "@/lib/fuel";
import ContractLoader from "@/views/happs/components/ContractLoader";
import BuyFuelWith from "@/components/fuel/BuyFuelWith.vue";
import FuelBalance from "@/views/happs/fuel/FuelBalance";
import FuelTokenSelect from "@/components/fuel/FuelTokenSelect.vue";
import BigNumber from "bignumber.js";
import { mapGetters, mapState } from "vuex";
import { every } from "@/lib/interval";
import FuelMixin from "@/components/fuel/FuelMixin";
import FuelSubscriptionsList from "@/views/happs/fuel/FuelSubscriptionsList.vue";
import LoginForm from "@/components/LoginForm.vue";
import FuelHistoryList from "@/views/happs/fuel/FuelHistoryList.vue";
import FuelTicketsList from "@/views/happs/fuel/FuelTicketsList.vue";

export default {
    components: {
        FuelTicketsList,
        FuelHistoryList,
        LoginForm,
        FuelSubscriptionsList,
        BuyFuelWith,
        FuelTokenSelect,
        FuelBalance,
        ContractLoader,
        ProgressCircle,
    },
    props: {
        page: String,
        token: String,
    },
    mixins: [FuelMixin],
    data() {
        return {
            lockOpen: false,
            asset: {
                address: fuelTokenAddress,
                network: fuelTokenNetwork,
            },
            tabs: ["Balance", "Subscriptions", "History", "Tickets"],
            active: "Balance",
        };
    },
    computed: {
        showBuy() {
            return this.page === "buy" && this.token;
        },
        hash() {
            return this.$route.hash;
        },
    },
    watch: {
        loggedIn(val) {
            if (val) {
                this.loadData();
            }
        },
        hash: {
            immediate: true,
            handler(val) {
                if (val.length > 1) {
                    this.active = val.slice(1);
                } else {
                    this.active = "Balance";
                }
            },
        },
    },
    methods: {
        newBalance(balance) {
            this.fuel.available = balance;
        },
        newLocked(locked) {
            this.fuel.locked = locked;
        },
        tokenSelected(address) {
            this.$router.push({ path: `/fuel/buy/${address}`, query: this.$route.query });
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.fuel-tabs {
    margin: 20px 0 40px;
}

.buy-fuel-wrapper {
    margin-bottom: 30px;
}

.fuel-balance-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;

    &.buy-active {
        @media screen and (max-width: 640px) {
            display: block;
        }
    }
}

#what-is-fuel {
    :deep(h3) {
        font-weight: normal;
    }

    :deep(h4) {
        margin: 20px 0 0;
    }
}

.footnote {
    margin-top: 40px;
}

.back-to-tokens {
    margin: 10px 0;
}
</style>
