import Api from "@/lib/api/WalletApi";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        dismissed: {},
        stack: [],
        steps: {}
    },
    mutations: {
        activate(state, key) {
            state.stack.push(key);
        },
        dismiss_help(state, key) {
            Vue.set(state.dismissed, key, true);
            state.stack = state.stack.filter(it => it !== key);
        },
        restore(state, dismissed) {
            state.dismissed = Object.assign({}, state.dismissed, dismissed);
        },
        hide_help(state, key) {
            state.stack = state.stack.filter(it => it !== key);
        }
    },
    getters: {
        activeHelp(state) {
            return state.stack[0];
        }
    },
    actions: {
        showHelp({commit, state}, key) {
            if (!state.stack.includes(key) && !state.dismissed[key]) {
                commit("activate", key);
            }
        },
        dismissHelp({commit, state, rootGetters}, key) {
            if (!state.dismissed[key]) {
                commit("dismiss_help", key);

                /**
                 * @type {Api}
                 */
                let api = rootGetters["user/api"];

                api.saveContractState("help", {
                    dismissed: state.dismissed
                })
                    .catch(console.error);
            }
        },
        async restoreHelp({commit, rootGetters}) {
            /**
             * @type {Api}
             */
            let api = rootGetters["user/api"];

            let restore = await api.getContractState("help");

            if (restore && restore.dismissed) {
                commit("restore", restore.dismissed);
            }
        }
    }
}
