import {requireGuest} from "@/router/lib/router-utils";
import NotFound from "@/views/NotFound";

export default {
    routes: [
        {
            path: '/',
            name: "Lepricon Wallet",
            component: () => import(/* webpackChunkName: "locks" */ '../views/happs/locks/Locks'),
            props: {
                address: "0xdef1da03061ddd2a5ef6c59220c135dec623116d",
                net: "mainnet",
                name: "Lepricon",
                symbol: "L3P",
                decimals: 18,
                logo: require('@/assets/images/logos/lepricon.png'),
                headingLogo: require('@/assets/images/logos/lepricon_white.png'),
                icon: require('@/assets/images/logos/lepricon_icon.png'),
                places: 2,
                stakedAddress: "0x9fedef2647A356F545c13b27F9E0e9d7F08C87e9",
                stakedNet: "leprichain",
                stakedSymbol: "SL3P",
                // whitelabel: true,
                // cssVars: {
                //     "--body": "#fdfef2",
                //     "--link": "#03a9f4",
                //     "--link-hover": "#22b5ff",
                //     "--large-heading-background": "#000",
                //     "--wallet-heading-background":
                //         "linear-gradient(45deg, rgba(107,244,122,1) 0%, rgba(255,255,173,1) 47%, rgba(237,253,177,1) 78%, rgba(82,240,212,1) 100%)",
                //     "--wallet-heading-text": "#000",
                //     "--primary": "#72f44e",
                //     "--primary-text": "#000",
                //     "--primary-muted": "#1d80f5",
                //     "--primary-button": "#000",
                //     "--primary-button-text": "#fff",
                //     "--info-button": "#3bb62c",
                //     "--info-button-text": "#fff",
                //     "--info-heading-background": "#eaf3f0",
                //     "--primary-muted-button": "#cc9c4b",
                //     "--primary-muted-button-text": "#fff",
                //     "--font-family-body-text": "'Sora', sans-serif",
                //     "--font-family-button": "'Sora', sans-serif",
                //     "--font-family-heading": "'Sora', sans-serif",
                //     "--button-radius": "3px",
                //     "--small-button-radius": "3px",
                //     "--button-font-weight": 400,
                // }
            }
        },
        {
            path: '/app/login',
            name: 'Login',
            beforeEnter: requireGuest,
            component: () => import(/* webpackChunkName: "login" */ '../views/app/Login')
        },
        {
            path: '*',
            beforeEnter(to, from, next) {
                window.location = "https://app.blockwell.ai" + to.fullPath;
            }
        }
    ]
}
