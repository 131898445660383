<template>
    <component :is="component" v-bind="properties"/>
</template>

<script>
import NotFound from "@/views/NotFound";
import Bwns from "@/lib/api/Bwns";

const bwns = new Bwns();

export default {
    props: {
        name: String
    },
    data() {
        return {
            component: "layout-default",
            properties: {}
        };
    },
    created() {
        bwns.resolve(this.name, 'link')
            .then(it => {
                if (/^https?:\/\//i.test(it.link)) {
                    window.location = it.link;
                } else {
                    let {route} = this.$router.resolve(it.link);
                    this.properties = {
                        ...route.params,
                        query: route.query,
                        hash: route.hash
                    };
                    let comp = this.$router.getMatchedComponents(it.link);
                    if (comp.length > 0) {
                        this.component = comp[0];
                    } else {
                        this.component = NotFound;
                    }
                }
            })
            .catch(err => {
                console.error(err);
                this.path = null;
            })
    }
}
</script>

<style scoped>

</style>
