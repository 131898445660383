import { props, requireGuest } from "@/router/lib/router-utils";
import DumbappPage from "@/views/dumbapp/DumbappPage";
import DumbappSharing from "@/views/dumbapp/DumbappSharing";
import NotFound from "@/views/NotFound";

export default {
    routes: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: "suggestions" */ '../views/happs/Suggestions'),
            meta: {
                title: "Voting"
            },
            props: {
                name: "defactor"
            }
        },
        {
            path: '/app/login',
            name: 'Login',
            beforeEnter: requireGuest,
            component: () => import(/* webpackChunkName: "login" */ '../views/app/Login')
        },
        {
            path: '/:shortcode([a-z0-9]{6})',
            name: "Dumbapp",
            component: DumbappPage,
            props
        },
        {
            path: '/:shortcode([a-z0-9]{6})/sharing',
            component: DumbappSharing,
            props
        },
        {
            path: '/app/account/submissions',
            name: "Dumbapp Submissions",
            component: () => import(/* webpackChunkName: "account" */ '../views/app/account/Submissions')
        },
        {
            path: '*',
            beforeEnter(to, from, next) {
                window.location = "https://app.blockwell.ai" + to.fullPath;
            }
        }
    ]
}
