import { isEmbed, isStakingWallet } from "@/lib/page";
import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import main from "./main";
import embed from "./embed";
import lepricon from "./lepricon";
import defactor from "./defactor";
import defactorVoting from "./defactor-voting";
import lt5Voting from "./lt5-voting";
import paid from "./paid";

Vue.use(VueRouter);

let domain;

if (isEmbed()) {
    domain = embed;
} else if (isStakingWallet("l3p", "wallet.lepricon.io")) {
    domain = lepricon;
} else if (isStakingWallet("defactor", "wallet.defactor.com")) {
    domain = defactor;
} else if (isStakingWallet("defactor-voting", "vote.defactor.com")) {
    domain = defactorVoting;
} else if (isStakingWallet("lt5-voting", "lt5.blockwell.ai")) {
    domain = lt5Voting;
} else if (isStakingWallet("paid")) {
    domain = paid;
} else {
    domain = main;
}

const router = new VueRouter({
    mode: "history",
    base: domain.base || "/",
    routes: domain.routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.meta.overrideHash || (to.name && to.name === from.name)) {
            return;
        }
        if (to.hash) {
            return {
                selector: to.hash,
                // , offset: { x: 0, y: 10 }
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

if (domain.beforeEach) {
    router.beforeEach(domain.beforeEach);
}

router.beforeEach((to, from, next) => {
    let ref = to.query["1r"];

    if (ref) {
        Cookies.set("bw_referrer", ref, {
            expires: 3,
        });
    }
    next();
});

router.onError((err) => {
    console.log(err.message);
    if (
        err.message ===
        "Duplicated method constructor. This method is defined as RPC call and as Object method."
    ) {
        return;
    }

    console.error("Error in router:", err.name);
    console.dir(err);
    if (err.name === "ChunkLoadError") {
        // Avoid infinite refreshes if the chunk loading is failing for reasons other than
        // server deployment.
        let refresh = parseInt(localStorage.getItem("chunkloaderror-refresh"));

        // Try refreshing if it has been longer than one minute since the last refresh
        if (isNaN(refresh) || !refresh || refresh < Date.now() - 60 * 1000) {
            localStorage.setItem("chunkloaderror-refresh", Date.now().toString());
            window.location.reload();
        }
    }
});

export default router;
