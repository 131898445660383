import Vue from "vue";


export default {
    namespaced: true,
    state: {
        keys: {},
        cacheTimestamps: {}
    },
    mutations: {
        save_key(state, {parameterString, key}) {
            Vue.set(state.keys, parameterString, key);
            Vue.set(state.cacheTimestamps, parameterString, new Date());
        },
        clear(state) {
            state.keys = {};
            state.cacheTimestamps = {};
        }
    },
    actions: {
        /**
         *
         * @param commit
         * @param state
         * @param rootGetters
         * @param {KeyParameters} parameters
         */
        async getKey({commit, state, rootGetters}, parameters) {
            if (!rootGetters["user/loggedIn"] || (!parameters.tokenId && !parameters.tokenAddress)) {
                return null;
            }
            let api = rootGetters["user/api"];

            let tokenId = parameters.tokenId;
            if (!tokenId) {
                let contract = await api.getContractId(parameters.tokenAddress, parameters.tokenNetwork);
                tokenId = contract.id;
            }

            let contractId = parameters.contractId;
            if (!contractId && parameters.contractAddress) {
                let contract = await api.getContractId(parameters.contractAddress, parameters.contractNetwork);
                contractId = contract.id;
            }

            let parameterString = [
                tokenId,
                parameters.version,
                contractId,
                parameters.address,
                parameters.timestamp
            ].filter(it => !!it).join("|");

            let key = state.keys[parameterString];
            let timestamp = state.cacheTimestamps[parameterString];

            if (timestamp === undefined || key === null && new Date() - timestamp > 15000) {
                key = await api.getEncryptionKey(tokenId, {
                    version: parameters.version,
                    address: parameters.address,
                    timestamp: parameters.timestamp,
                    contractId
                });

                commit("save_key", {parameterString, key});
            }

            return key;
        }
    }
}
