import {requireGuest} from "@/router/lib/router-utils";
import NotFound from "@/views/NotFound";

export default {
    routes: [
        {
            path: '/',
            name: "PAID Staking",
            component: () => import(/* webpackChunkName: "locks" */ '../views/happs/locks/Locks'),
            props: {
                address: "0x3e21826b3791ffc8ea3d7430d25a2fad354ab53f",
                net: "rinkeby",
                name: "PAID",
                symbol: "PAID",
                decimals: 18,
                logo: require('@/assets/images/icons/paid.png'),
                places: 2,
                stakedAddress: "0x93A9112feD4E401329afdE91FBd9644C62B073a4",
                stakedNet: "firechain",
                stakedSymbol: "sPAID"
            }
        },
        {
            path: '/app/login',
            name: 'Login',
            beforeEnter: requireGuest,
            component: () => import(/* webpackChunkName: "login" */ '../views/app/Login')
        },
        {
            path: '*',
            beforeEnter(to, from, next) {
                window.location = "https://app.blockwell.ai" + to.fullPath;
            }
        }
    ]
}
