import account from "@/router/account";
import app from "@/router/app";
import { props, requireAuth } from "@/router/lib/router-utils";
import Apps from "@/views/Apps";
import Deposit from "@/views/Deposit";
import DumbappPage from "@/views/dumbapp/DumbappPage";
import DumbappSharing from "@/views/dumbapp/DumbappSharing";
import DumbappSubmissionPage from "@/views/dumbapp/DumbappSubmissionPage";
import Link from "@/views/Link";
import NotFound from "@/views/NotFound";
import Offer from "@/views/Offer";
import Prime from "@/views/Prime";
import Homepage from "@/views/Homepage";
import Fuel from "@/views/happs/fuel/Fuel";
import Vue from "vue";
import * as moment from "moment";

export default {
    beforeEach: (to, from, next) => {
        if (
            !to.meta.skipTitle &&
            (document.title === "" ||
                document.title === "Blockwell Wallet" ||
                (from && from.fullPath !== "/"))
        ) {
            let sitename = "Blockwell Wallet";
            if (to.meta.title) {
                document.title = to.meta.title + " | " + sitename;
            } else if (to.name) {
                document.title = to.name + " | " + sitename;
            } else {
                document.title = sitename;
            }
        }

        if (to.path === "/4hx4yo" || to.path === "/bh8s4y") {
            next(`/offer/${to.query.i || ""}`);
        } else {
            Vue.prototype.$bus.emit("route", to);
            next();
        }
    },
    routes: [
        {
            path: "/",
            component: Homepage,
            beforeEnter(to, from, next) {
                if (
                    window.location.hostname.toLowerCase() !== "blockwell.ai" &&
                    window.location.hostname.toLowerCase() !== "www.blockwell.ai"
                ) {
                    next("/app/wallet");
                } else {
                    next();
                }
            },
        },
        {
            path: "/homepage",
            component: Homepage,
        },
        {
            path: "/qex",
            component: () => import(/* webpackChunkName: "qe" */ "../views/Qe"),
            beforeEnter: requireAuth,
        },
        {
            path: "/uniswap/:token?",
            name: "Blockwell Uniswap",
            component: () => import(/* webpackChunkName: "qe" */ "../views/happs/uniswap/Uniswap"),
            props,
        },
        {
            path: "/suggestions",
            name: "Voting",
            component: () =>
                import(/* webpackChunkName: "suggestions" */ "../views/happs/Suggestions"),
        },
        {
            path: "/suggestions/:name",
            component: () =>
                import(/* webpackChunkName: "suggestions" */ "../views/happs/Suggestions"),
            meta: {
                title: "Voting",
            },
        },
        {
            path: "/vote/:name?",
            name: "Voting",
            component: () => import(/* webpackChunkName: "nft" */ "../views/happs/vote/Vote"),
            meta: {
                title: "Voting",
            },
            props,
        },
        {
            path: "/market/:name?",
            name: "Market",
            component: () => import(/* webpackChunkName: "nft" */ "../views/happs/market/Market"),
            meta: {
                title: "Market",
            },
            props,
        },
        {
            path: "/lotto/:name?",
            name: "Lotto",
            component: () => import(/* webpackChunkName: "nft" */ "../views/happs/lotto/Lotto"),
            meta: {
                title: "Lotto",
                whitelabelId: "lotto",
            },
            props,
        },
        {
            path: "/daico",
            name: "Daico",
            component: () => import(/* webpackChunkName: "daico" */ "../views/happs/Daico"),
        },
        {
            path: "/daico/:name",
            component: () => import(/* webpackChunkName: "daico" */ "../views/happs/Daico"),
            meta: {
                title: "Daico",
            },
        },
        {
            path: "/book/:name?",
            component: () => import(/* webpackChunkName: "book" */ "../views/happs/book/Book"),
            meta: {
                title: "Book",
                overrideHash: true,
            },
            props,
        },
        {
            name: "BookPage",
            path: "/bookpage/:name?",
            component: () => import(/* webpackChunkName: "faq" */ "../views/BookPage"),
            meta: {
                overrideHash: true,
            },
            props,
        },
        {
            path: "/smart/:name?",
            component: () =>
                import(/* webpackChunkName: "smart" */ "../views/happs/smart-license/SmartLicense"),
            meta: {
                title: "Smart License",
            },
            props,
        },
        {
            path: "/smart-license",
            redirect: "/189cev",
        },
        {
            path: "/nft/:name?",
            name: "NFT",
            component: () => import(/* webpackChunkName: "nft" */ "../views/happs/nft/Nft"),
            meta: {
                title: "NFT",
            },
            props,
        },
        {
            path: "/nft-swap/:name?",
            name: "NFT Swap",
            component: () => import(/* webpackChunkName: "nft" */ "../views/happs/nftswap/NftSwap"),
            meta: {
                title: "NFT Swap",
            },
            props,
        },
        {
            path: "/hourglass/:name?",
            name: "Hourglass",
            component: () =>
                import(/* webpackChunkName: "hourglass" */ "../views/happs/hourglass/Hourglass"),
            meta: {
                title: "Hourglass",
            },
            props,
        },
        {
            path: "/core/:name?",
            component: () => import(/* webpackChunkName: "core" */ "../views/happs/core/Core"),
            meta: {
                title: "Core",
                overrideHash: true,
            },
            props,
        },
        {
            path: "/core-v1/:name?",
            component: () => import(/* webpackChunkName: "core" */ "../views/happs/oldcore/Core"),
            meta: {
                title: "Core",
                overrideHash: true,
            },
            props,
        },
        {
            name: "BWNS",
            path: "/bwns",
            component: () => import(/* webpackChunkName: "bwns" */ "../views/Bwns"),
        },
        {
            name: "Contribute Tokens",
            path: "/contribute",
            component: () => import(/* webpackChunkName: "contrib" */ "../views/Contrib"),
        },
        {
            name: "Dumbapps",
            path: "/dumbapps",
            redirect(to) {
                return {
                    path: "/bapps",
                    query: to.query,
                    hash: to.hash,
                };
            },
        },
        {
            name: "bApps",
            path: "/bapps",
            component: () => import(/* webpackChunkName: "dumbapps" */ "../views/Dumbapps"),
            props,
        },
        {
            name: "FAQ",
            path: "/faq",
            component: () => import(/* webpackChunkName: "faq" */ "../views/Faq"),
        },
        {
            name: "Materials",
            path: "/materials",
            component: () => import(/* webpackChunkName: "faq" */ "../views/Materials"),
        },
        {
            name: "Titans",
            path: "/titan",
            component: () => import(/* webpackChunkName: "faq" */ "../views/Titan"),
        },
        {
            path: "/kyc",
            component: () => import(/* webpackChunkName: "kyc" */ "../views/Kyc"),
            meta: {
                title: "KYC",
            },
        },
        {
            path: "/kyc/:org",
            component: () => import(/* webpackChunkName: "kyc" */ "../views/Kyc"),
            meta: {
                title: "KYC",
            },
        },
        {
            path: "/palette/:type",
            component: () => import(/* webpackChunkName: "palette" */ "../views/Palette"),
            meta: {
                title: "Palette",
            },
        },
        {
            path: "/palette2",
            component: () =>
                import(/* webpackChunkName: "palette" */ "../views/palette/PaletteLanding.vue"),
            meta: {
                title: "Palette",
            },
            props,
        },
        {
            path: "/palette2/:name/:path*",
            component: () => import(/* webpackChunkName: "palette" */ "../views/palette/Palette2"),
            meta: {
                title: "Palette",
                skipTitle: true,
                overrideHash: true,
            },
            props,
        },
        {
            path: "/palettes/:name/:path*",
            component: () =>
                import(/* webpackChunkName: "palette" */ "../views/palette/PaletteViewer.vue"),
            meta: {
                title: "Palette",
                skipTitle: true,
                overrideHash: true,
            },
            props,
        },
        {
            name: "Quick Cipher",
            path: "/qc",
            component: () => import(/* webpackChunkName: "qc" */ "../views/QuickCipher"),
        },
        ...app,
        ...account,
        { path: "/app", redirect: "/app/wallet" },
        {
            path: "/:shortcode([a-z0-9]{6})",
            name: "Dumbapp",
            component: DumbappPage,
            props,
        },
        {
            path: "/:shortcode([a-z0-9]{6})/sharing",
            component: DumbappSharing,
            props,
        },
        {
            path: "/submission/:submissionId",
            name: "Dumbapp Submission",
            component: DumbappSubmissionPage,
            props,
        },
        {
            path: "/creator/:shortcode?",
            component: () => import(/* webpackChunkName: "creator" */ "../views/dumbapp/Creator"),
            meta: {
                title: "bApp Creator",
            },
            props,
        },
        {
            path: "/code",
            redirect: "/creator",
        },
        {
            path: "/code/advanced",
            redirect: "/creator",
        },
        {
            path: "/code/:shortcode",
            redirect(to) {
                return `/${to.params.shortcode}/sharing`;
            },
        },
        {
            path: "/link/:name",
            component: Link,
            props,
        },
        {
            path: "/apps",
            name: "Blockwell Apps",
            component: Apps,
        },
        {
            path: "/offer/:reference?",
            name: "Contribute",
            component: Offer,
            props,
        },
        {
            path: "/freelance/:view?/:contract?/:taskId?",
            name: "Freelance",
            component: () =>
                import(
                    /* webpackChunkName: "core" */ "../views/happs/core/freelance/FreelanceBoard"
                ),
            beforeEnter: requireAuth,
            props,
        },
        {
            path: "/prime",
            name: "Create ERC-20 Token",
            component: Prime,
        },
        {
            path: "/deposit/:reference",
            name: "Pay Deposit",
            component: Deposit,
            props,
        },
        {
            path: "/l3p",
            name: "Lepricon Wallet",
            component: () => import(/* webpackChunkName: "locks" */ "../views/happs/locks/Locks"),
            props: {
                external: "https://lepricon.io",
                address: "0xdef1da03061ddd2a5ef6c59220c135dec623116d",
                net: "mainnet",
                name: "Lepricon",
                symbol: "L3P",
                decimals: 18,
                logo: require("@/assets/images/logos/lepricon_icon.png"),
                headingLogo: require("@/assets/images/logos/lepricon_white.png"),
                places: 2,
                stakedAddress: "0x9fedef2647A356F545c13b27F9E0e9d7F08C87e9",
                stakedNet: "leprichain",
                stakedSymbol: "SL3P",
                whitelabel: true,
                velvet: "lepricon",
                cssVars: {
                    "--body": "#fdfef2",
                    "--body-text": "#000",
                    "--link": "#03a9f4",
                    "--link-hover": "#22b5ff",
                    "--large-heading-background": "#000",
                    "--wallet-heading-background":
                        "linear-gradient(45deg, rgba(107,244,122,1) 0%, rgba(255,255,173,1) 47%, rgba(237,253,177,1) 78%, rgba(82,240,212,1) 100%)",
                    "--wallet-heading-text": "#000",
                    "--primary": "#72f44e",
                    "--primary-text": "#000",
                    "--primary-muted": "#1d80f5",
                    "--primary-button": "#000",
                    "--primary-button-text": "#fff",
                    "--info-button": "#3bb62c",
                    "--info-button-text": "#fff",
                    "--info-heading-background": "#eaf3f0",
                    "--primary-muted-button": "#cc9c4b",
                    "--primary-muted-button-text": "#fff",
                    "--font-family-body-text": "'Sora', sans-serif",
                    "--font-family-button": "'Sora', sans-serif",
                    "--font-family-heading": "'Sora', sans-serif",
                    "--button-radius": "3px",
                    "--small-button-radius": "3px",
                    "--button-font-weight": 400,
                },
            },
        },
        {
            path: "/testl3p",
            name: "Lepricon Lockups Test",
            component: () => import(/* webpackChunkName: "locks" */ "../views/happs/locks/Locks"),
            props: {
                address: "0x019b9b5f9fad299196ef02f4acb53f6e8b3bebb9",
                net: "rinkeby",
                name: "testL3P",
                symbol: "testL3P",
                decimals: 18,
                logo: require("@/assets/images/logos/lepricon.png"),
                places: 2,
                stakedAddress: "0x80edc5ae6de1415b878d234b24568250a95cc9fb",
                stakedNet: "leprichain",
                stakedSymbol: "sL3P",
            },
        },
        {
            path: "/lock2",
            name: "LockTest2",
            component: () => import(/* webpackChunkName: "locks" */ "../views/happs/locks/Locks"),
            props: {
                address: "0xbd69c8971087937d4cf1f64e674bd7866b592ae9",
                net: "rinkeby",
                name: "Private LockTest",
                symbol: "PLT",
                decimals: 18,
                logo: require("@/assets/images/icons/bw.svg"),
                places: 2,
                stakedAddress: "0xacdbe71701224d7c125fcc1b38b7d6f21fe1c96c",
                stakedNet: "leprichain",
                stakedSymbol: "PLTS",
            },
        },
        {
            path: "/lock3",
            name: "LockTest5",
            component: () =>
                import(/* webpackChunkName: "locks" */ "../views/happs/multichain/Multichain"),
            props: {
                name: "SwapTest",
                symbol: "SWTEST",
                decimals: 18,
                logo: require("@/assets/images/icons/bw.svg"),
                places: 2,
                chains: [
                    {
                        net: "rinkeby",
                        address: "0xf002421d1556B9eF68976a30748682f8B9f084a1",
                    },
                    {
                        net: "firechain",
                        address: "0xf002421d1556B9eF68976a30748682f8B9f084a1",
                    },
                ],
            },
        },
        {
            path: "/whitelabel-test",
            name: "WhitelabelTest",
            component: () =>
                import(/* webpackChunkName: "locks" */ "../views/happs/multichain/Multichain"),
            props: {
                name: "WhitelabelTest",
                symbol: "WhT",
                decimals: 18,
                logo: require("@/assets/images/icons/bw.svg"),
                places: 2,
                chains: [
                    {
                        net: "rinkeby",
                        address: "0x222222226e1e63bf66886f00DB2c77D6442F910d",
                        registry: true,
                    },
                    {
                        net: "firechain",
                        address: "0x222222226e1e63bf66886f00DB2c77D6442F910d",
                    },
                ],
            },
            meta: {
                whitelabelId: "whitelabel-test",
            },
        },
        {
            path: "/striptest",
            name: "$STRIPTEST",
            component: () =>
                import(/* webpackChunkName: "locks" */ "../views/happs/multichain/Multichain"),
            props: {
                name: "$STRIPTEST",
                symbol: "$STRIPTEST",
                decimals: 18,
                logo: require("@/assets/images/icons/bw.svg"),
                places: 2,
                chains: [
                    {
                        net: "goerli",
                        address: "0xC001DA01867A567Cce1b5De38e93a2C21d225266",
                    },
                    {
                        net: "stripchain",
                        address: "0xC001DA01867A567Cce1b5De38e93a2C21d225266",
                    },
                ],
            },
            meta: {
                whitelabelId: "striptest",
            },
        },
        {
            path: "/strip",
            name: "STRIP",
            component: () =>
                import(/* webpackChunkName: "locks" */ "../views/happs/multichain/Multichain"),
            props: {
                name: "STRIP",
                symbol: "STRIP",
                decimals: 18,
                logo: require("@/assets/images/icons/bw.svg"),
                places: 2,
                chains: [
                    {
                        net: "mainnet",
                        address: "0xB00B1E26e608Dce631db71c556bA1cf378aFFbEb",
                    },
                    {
                        net: "stripchain",
                        address: "0xB00B1E26e608Dce631db71c556bA1cf378aFFbEb",
                        registry: true,
                    },
                ],
            },
            meta: {
                whitelabelId: "strip",
            },
        },
        {
            path: "/testr",
            name: "TestFactor",
            component: () =>
                import(/* webpackChunkName: "locks" */ "../views/happs/multichain/Multichain"),
            props: {
                name: "TestFactor",
                symbol: "TESTR",
                decimals: 18,
                logo: require("@/assets/images/logos/defactor.png"),
                places: 2,
                chains: [
                    {
                        net: "rinkeby",
                        address: "0x80D98D93F412E565dBd3f13e7076581feB39c165",
                    },
                    {
                        net: "defactor",
                        address: "0x80D98D93F412E565dBd3f13e7076581feB39c165",
                        suggestions: true,
                        staking: true,
                    },
                ],
                stakingBook: "staking-testr",
            },
        },
        {
            path: "/defactor",
            name: "Defactor",
            component: () =>
                import(/* webpackChunkName: "locks" */ "../views/happs/multichain/Multichain"),
            props: {
                name: "Defactor",
                symbol: "FACTR",
                decimals: 18,
                logo: require("@/assets/images/logos/defactor.png"),
                headingLogo: require("@/assets/images/logos/defactor_white.png"),
                places: 2,
                chains: [
                    {
                        net: "mainnet",
                        address: "0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7",
                    },
                    {
                        net: "defactor",
                        address: "0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7",
                        suggestions: true,
                        staking: true,
                    },
                    {
                        net: "bsc",
                        address: "0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7",
                    },
                ],
                stakingBook: "staking-factr",
                whitelabel: true,
                velvet: "defactor",
                external: "https://defactor.com/",
                cssVars: {
                    "--body": "#ffffff",
                    "--body-text": "#000",
                    "--link": "#a2539b",
                    "--link-hover": "#b76cb0",
                    "--large-heading-background": "#000c22",
                    "--wallet-heading-background": "#0f1629",
                    "--wallet-heading-text": "#fff",
                    "--primary": "#d32d7a",
                    "--primary-text": "#fff",
                    "--primary-muted": "#91184f",
                    "--primary-button": "#d32d7a",
                    "--primary-button-text": "#fff",
                    "--info-button": "#a2539b",
                    "--info-button-text": "#fff",
                    "--info-heading-background": "#f6eaea",
                    "--primary-muted-button": "#cc9c4b",
                    "--primary-muted-button-text": "#fff",
                    "--font-family-body-text": "'Poppins', sans-serif",
                    "--font-family-button": "'Poppins', sans-serif",
                    "--font-family-heading": "'Poppins', sans-serif",
                    "--button-radius": "1px",
                    "--small-button-radius": "1px",
                    "--button-font-weight": 400,
                },
            },
        },
        {
            path: "/paid",
            name: "PAID Staking",
            component: () => import(/* webpackChunkName: "locks" */ "../views/happs/locks/Locks"),
            props: {
                address: "0x3e21826b3791ffc8ea3d7430d25a2fad354ab53f",
                net: "rinkeby",
                name: "PAID",
                symbol: "PAID",
                decimals: 18,
                logo: require("@/assets/images/icons/paid.png"),
                places: 2,
                stakedAddress: "0x93A9112feD4E401329afdE91FBd9644C62B073a4",
                stakedNet: "firechain",
                stakedSymbol: "sPAID",
            },
        },
        {
            path: "/commfactor",
            name: "CommFactor Staking",
            component: () => import(/* webpackChunkName: "locks" */ "../views/happs/locks/Locks"),
            props: {
                address: "0x58443a905f4c74f648262284defe8d6da2272095",
                net: "goerli",
                name: "CommFactor",
                symbol: "CF",
                decimals: 18,
                logo: require("@/assets/images/logos/defactor.png"),
                places: 2,
                stakedAddress: "0x4ebb8e7ddad2db58be940c903f8c7bcae7735413",
                stakedNet: "rinkeby",
                stakedSymbol: "SCF",
            },
        },
        {
            path: "/fuel/:page?/:token?",
            name: "Fuel",
            component: Fuel,
            props,
        },
        ...greeting(
            "Crypto Greeting",
            "greeting",
            "0x4e31fAE1dbF659E2De4bAcC154b7658138973076",
            "rinkeby"
        ),
        ...greeting(
            "TESTNET Greeting",
            "testnet-greeting",
            "0x4e31fAE1dbF659E2De4bAcC154b7658138973076",
            "rinkeby"
        ),
        {
            path: "/workbook/:period?",
            component: () => import(/* webpackChunkName: "book" */ "../views/happs/book/Book"),
            meta: {
                title: "WorkBook",
                overrideHash: true,
            },
            props(route) {
                return {
                    ...route.params,
                    name: "workbook",
                };
            },
        },
        {
            path: "/automate",
            component: () =>
                import(/* webpackChunkName: "automate" */ "../views/automate/Automate.vue"),
            meta: {
                title: "Automate",
            },
            props,
        },
        {
            path: "/automate/create",
            component: () =>
                import(/* webpackChunkName: "automate" */ "../views/automate/CreateLink.vue"),
            meta: {
                title: "Automate - Create Link",
            },
            props,
        },
        {
            path: "/automate/add",
            component: () =>
                import(/* webpackChunkName: "automate" */ "../views/automate/AddProcessor.vue"),
            meta: {
                title: "Automate - Add Processor",
            },
            props,
        },
        {
            path: "/automate/links/:id",
            component: () =>
                import(/* webpackChunkName: "automate" */ "../views/automate/FiatLinkView.vue"),
            meta: {
                title: "Automate Link",
            },
            props,
        },
        {
            path: "*",
            name: "NotFound",
            component: NotFound,
        },
    ],
};

function greeting(title, basePath, relayAddress, relayNetwork) {
    return [
        {
            path: "/" + basePath,
            name: title,
            component: () => import(/* webpackChunkName: "gift" */ "../views/gift/Gift"),
            props(route) {
                return {
                    query: route.query,
                    title,
                    basePath,
                };
            },
        },
        {
            path: `/${basePath}/personalize/:tokenId`,
            name: "Personalize " + title,
            component: () => import(/* webpackChunkName: "gift" */ "../views/gift/Personalize"),
            props(route) {
                return {
                    ...route.params,
                    query: route.query,
                    title,
                    relayAddress,
                    relayNetwork,
                    basePath,
                };
            },
        },
        {
            path: `/${basePath}/summary/:tokenId`,
            name: title + " Summary",
            component: () => import(/* webpackChunkName: "gift" */ "../views/gift/Summary"),
            props(route) {
                return {
                    ...route.params,
                    query: route.query,
                    pageTitle: title,
                    relayAddress,
                    relayNetwork,
                    basePath,
                };
            },
        },
    ];
}
