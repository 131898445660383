import dayjs from "dayjs";
export default {
    name: "FuelSubscriptionRow",
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {};
    },
    computed: {
        expiration() {
            if (this.item?.expires) {
                return dayjs(this.item.expires).format("ll");
            }
            return null;
        },
        active() {
            const item = this.item;
            return item?.status === "active" || item?.status === "cancelled";
        }
    },
    methods: {
        cancel() {
            const api = this.$store.getters["user/api"].fuel;
            api.cancelSubscription(this.item.id)
                .then(() => this.$emit("update"));
        },
        restart() {
            const api = this.$store.getters["user/api"].fuel;
            api.restartSubscription(this.item.id)
                .then(() => this.$emit("update"));
        }
    }
};
