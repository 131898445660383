import localforage from "localforage";
import VuexPersistence from "vuex-persist";
import { Dumbapp, DumbappSubmissionData } from "@blockwell/dumbapp";
let cookieDomain = undefined;
if (window.location.hostname === process.env.VUE_APP_COOKIE_DOMAIN ||
    window.location.hostname.endsWith("." + process.env.VUE_APP_COOKIE_DOMAIN)) {
    cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
}
const modules = {
    drafts: {},
    dumbapp: {
        restore(dumbappState) {
            if (dumbappState) {
                let subs = dumbappState.submissionData;
                if (subs) {
                    let submissionData = {};
                    for (let data of Object.values(subs)) {
                        submissionData[data.id] = new DumbappSubmissionData(data);
                    }
                    dumbappState.submissionData = submissionData;
                }
                let dumbs = dumbappState.dumbapps;
                if (dumbs) {
                    let dumbapps = {};
                    for (let dumbapp of Object.values(dumbs)) {
                        dumbapps[dumbapp.shortcode] = new Dumbapp(dumbapp);
                    }
                    dumbappState.dumbapps = dumbapps;
                }
            }
        },
    },
    help: {
        include: ["dismiss_help", "restore"],
    },
    locks: {},
    tutorial: {},
    rolodex: {
        exclude: [/^debug/],
        restore(rolodexState) {
            // Make sure the walIndex is reset to -1
            console.log("walIndex: restoring to ", rolodexState.walIndex);
            //rolodexState.walIndex = -1;
        },
    },
    whitelabel: {
        reduce(state) {
            return {
                config: state.config,
                pages: state.pages,
            };
        },
        exclude: ["update_defaults", "show_whitelabel", "remove_show_whitelabel"],
    },
    palette: {}
};
export const persistence = new VuexPersistence({
    storage: localforage,
    asyncStorage: true,
    async restoreState(key, st) {
        let storage = st;
        let state = {};
        await storage.iterate(function (value, key) {
            // The object in storage has a single key with the same name, and then the actual object
            let val = value[key];
            let mod = modules[key];
            if (mod && mod.restore) {
                mod.restore(val);
            }
            state[key] = val;
        });
        return state;
    },
    async saveState(key, state, st) {
        let storage = st;
        await Promise.all(Object.entries(state).map(async ([key, val]) => {
            let mod = modules[key];
            if (!mod) {
                return;
            }
            let reducer = mod.reduce;
            let value = {};
            // The object in storage has a single key with the same name, and then the actual object
            value[key] = reducer ? reducer(val) : val;
            try {
                return await storage.setItem(key, value);
            }
            catch (err) {
                throw err;
            }
        }));
    },
    filter(mutation) {
        let split = mutation.type.split("/");
        let key = split[0];
        let name = split[1];
        let mod = modules[key];
        if (mod) {
            if (mod.include && !testList(mod.include, name)) {
                return false;
            }
            if (mod.exclude && testList(mod.exclude, name)) {
                return false;
            }
            return true;
        }
        return false;
    },
});
function testList(list, value) {
    for (let it of list) {
        if (typeof it === "string") {
            if (it === value) {
                return true;
            }
        }
        else {
            if (it.test(value)) {
                return true;
            }
        }
    }
    return false;
}
