import { every } from "@/lib/interval";
import FuelSubscriptionRow from "@/views/happs/fuel/FuelSubscriptionRow.vue";
export default {
    name: "FuelSubscriptionsList",
    components: { FuelSubscriptionRow },
    data() {
        return {
            list: [],
            interval: null,
        };
    },
    methods: {
        update() {
            this.interval?.runNow();
        }
    },
    created() {
        this.interval = every(() => {
            const api = this.$store.getters["user/api"].fuel;
            api.subscriptions().then(({ data, products }) => {
                this.list = data.map(it => {
                    return {
                        ...it,
                        product: products.find(prod => prod.id === it.productId)
                    };
                });
            });
        }, 20000, true);
    },
    beforeDestroy() {
        this.interval?.clear();
        this.interval = null;
    }
};
